import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { purple } from '../../styles/_variables.scss';
import { getExerciseProgression, getQuestionProgression } from '../../utils/exerciseProgression';
import RadialProgress from '../RadialProgress';
import './style.scss';

const ProgressionCircles = ({ exerciseNumber, questionNumber }) => {
    const {
        completedPartsCount,
        progressionPosition,
    } = useMemo(() => getQuestionProgression(exerciseNumber, questionNumber), [exerciseNumber, questionNumber]);
    const progression = useMemo(() => getExerciseProgression(exerciseNumber), [exerciseNumber]);
    const pieCounts = progression.length;
    const getCompletedPiePartsCount = useCallback((currentProgressionIndex) => {
        if (currentProgressionIndex < progressionPosition) {
            return progression[currentProgressionIndex];
        }
        if (currentProgressionIndex > progressionPosition) {
            return 0;
        }

        return completedPartsCount;
    }, [progression, progressionPosition, completedPartsCount]);

    const getPiePartsCount = useCallback(
        (currentProgressionIndex) => progression[currentProgressionIndex],
        [progression],
    );

    const circles = [];
    for (let i = 0; i < pieCounts; i++) {
        circles.push(
            <React.Fragment key={i}>
                <RadialProgress
                    value={getCompletedPiePartsCount(i)}
                    maxValue={getPiePartsCount(i)}
                    color={purple}
                />
                {i < pieCounts - 1 && <div className="inter-progression-circle" />}
            </React.Fragment>,
        );
    }

    return <div className="progression-circles-container">{circles}</div>;
};

ProgressionCircles.propTypes = {
    exerciseNumber: PropTypes.number.isRequired,
    questionNumber: PropTypes.number.isRequired,
};

export default ProgressionCircles;
