import React from 'react';
import { useMenu } from '../../../hooks/useMenu';

const MenuButton = () => {
    const { openMenu } = useMenu();

    return (
        <button onClick={() => openMenu(true)} type="button">
            <img alt="Menu" src="/img/icons/burger.svg" />
        </button>
    );
};

export default MenuButton;
