import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { useAppData } from '../../../hooks/useAppData';
import { useUrlParams } from '../../../hooks/useLocalizedHistoryPush';
import LanguageContext from '../../../Language/LanguageContext';
import { FORMS } from '../../../utils/constants';
import { getAllLangs } from '../../../utils/lang';
import {
    emailFormat,
    required,
    requireEmail,
    requireLanguage,
    requireName,
    requirePassword,
    requireSurname,
} from '../../../utils/validators';
import Button from '../../common/Button';
import CheckboxField from '../../Form/CheckboxField';
import SimpleField from '../../Form/Field';
import SelectField from '../../Form/SelectField';
import SsoBanner from '../../SsoBanner';

const SignInForm = ({ error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const { AUTH_URL } = useAppData();
    const updateUrlParams = useUrlParams();

    return (
        <>
            <h1 className="title">{t('auth.signin.title')}</h1>
            <SsoBanner />
            <form className="base-margin" method="post" onSubmit={handleSubmit}>
                {error && <div className="error">{t(error)}</div>}
                <div className="auth-form__name-fields">
                    <Field
                        className="margin-right-16"
                        component={SimpleField}
                        label={t('auth.signin.surname.placeholder')}
                        name="surname"
                        type="text"
                        validate={[requireSurname]}
                    />
                    <Field
                        component={SimpleField}
                        label={t('auth.signin.name.placeholder')}
                        name="name"
                        type="text"
                        validate={[requireName]}
                    />
                </div>
                <Field
                    component={SimpleField}
                    label={t('auth.signin.email.placeholder')}
                    name="email"
                    type="email"
                    validate={[requireEmail, emailFormat]}
                />
                <Field
                    component={SimpleField}
                    label={t('auth.signin.password.placeholder')}
                    name="password"
                    type="password"
                    validate={[requirePassword]}
                />
                <Field
                    component={SelectField}
                    name="language"
                    options={getAllLangs().map(({ keyLang, label }) => ({ value: keyLang, label }))}
                    validate={[requireLanguage]}
                />

                <Field
                    component={CheckboxField}
                    name="cgu"
                    validate={[required]}
                >
                    <>
                        {t('auth.signin.checkbox.text1')}
                        &nbsp;
                        <Link className="link strong" to={`/${language}/tos`}>{t('common.terms_of_service')}</Link>
                        &nbsp;
                        {t('auth.signin.checkbox.text2')}
                        &nbsp;
                        <Link className="link strong" to={`/${language}/confidentiality`}>{t('common.confidentiality.title')}</Link>
                        &nbsp;
                        {t('auth.signin.checkbox.text3')}
                    </>
                </Field>
                <div>
                    <Button
                        className="button--block button--black base-margin"
                        disabled={!valid}
                        submitting={submitting}
                        text={t('auth.signin.sign_in')}
                        submit
                    />
                    <Button
                        className="button--block button--outline base-margin"
                        img="/img/icons/google.svg"
                        text={t('auth.signin.google')}
                        onClick={() => {
                            window.location.href = `${AUTH_URL}/api/auth/marco-v3/google`;

                            return null;
                        }}
                    />
                </div>
            </form>
            <div className="base-margin auth-form__redirectLink">
                <span>{t('auth.signin.already_an_account')}</span>
                {' '}
                <button
                    className="link strong"
                    onClick={() => updateUrlParams({ open: FORMS.LOGIN })}
                    type="button"
                >
                    {t('auth.signin.go_to_login')}
                </button>
            </div>
        </>
    );
};

SignInForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

const mapStateToProps = (state) => ({ forms: state.form });

export default connect(
    mapStateToProps,
    null,
)(
    reduxForm({
        form: 'SignInForm',
    })(SignInForm),
);
