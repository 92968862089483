import React, { useEffect } from 'react';
import useLogout from '../hooks/useLogout';

const Logout = () => {
    const logout = useLogout();

    useEffect(() => {
        logout();
    }, []);

    return <p>Leaving...</p>;
};

export default Logout;
