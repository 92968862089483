import PropTypes from 'prop-types';
import React from 'react';
import { progressionCicleBorder } from '../../styles/_variables.scss';
import './style.scss';

const RADIUS = 15;

const RadialProgress = ({ value, maxValue, color }) => {
    const degrees = value * 360 / maxValue;
    const radians = (90 - degrees) * Math.PI / 180;

    const arcEnd = {
        x: RADIUS + (Math.cos(radians) * RADIUS),
        y: RADIUS + (Math.sin(radians) * RADIUS * -1), // -1 as y-axis is reversed in SVG coordinate system
    };

    const isFull = value === maxValue;

    return (
        <div className="radial-progress-wrapper">
            <svg height="30" width="30">
                { isFull
                    ? <circle cx={RADIUS} cy={RADIUS} r={RADIUS} fill={color} shapeRendering="geometricPrecision" />
                    : (
                        <>
                            <circle shapeRendering="geometricPrecision" cx={RADIUS} cy={RADIUS} r={RADIUS - 2} fill="transparent" stroke={progressionCicleBorder} strokeDasharray="4" strokeWidth="2.5" />
                            <path shapeRendering="geometricPrecision" d={`M${RADIUS},${RADIUS} L${RADIUS},0 A${RADIUS},${RADIUS} 0 ${180 <= degrees ? 1 : 0} 1 ${arcEnd.x},${arcEnd.y} z`} fill={color} />
                        </>
                    )
                }
            </svg>
            { isFull && (
                <svg className="radial-progress-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
                    <path
                        shapeRendering="geometricPrecision"
                        fill="#ffffff"
                        fillRule="evenodd"
                        d="M7.265 16c-.314 0-.614-.129-.826-.354L.286 9.048a1.046 1.046 0 0 1 .082-1.515 1.142 1.142 0 0 1 1.57.08l5.282 5.663L18.025.398a1.139 1.139 0 0 1 1.563-.159c.477.373.55 1.048.164 1.508L8.129 15.602c-.205.245-.51.389-.835.397l-.03.001"
                    />
                </svg>
            )}
        </div>
    );
};

RadialProgress.propTypes = {
    value: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    color: PropTypes.string,
};

RadialProgress.defaultProps = {
    color: '#333333',
};

export default RadialProgress;
