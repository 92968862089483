import PropTypes from 'prop-types';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { deleteResponseFromExercise } from '../../../actions';
import Button from '../../../components/common/Button';
import Modal from '../../../components/common/Modal';
import Container from '../../../components/layout/Container';
import MainWithStep from '../../../components/layout/MainWithStep';
import Panel from '../../../components/layout/Panel';
import DescriptionInterstep from '../../../components/PanelDescription/DescriptionInterstep';
import StepList from '../../../components/StepList';
import requireLastFormId from '../../../hoc/requireLastFormId';
import useLocalizedHistoryPush from '../../../hooks/useLocalizedHistoryPush';
import { getExerciseInfos } from '../../../utils/exercises';
import './style.scss';

const StepTransition = ({ lastFormId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { step } = useParams();
    const push = useLocalizedHistoryPush();
    const exerciseNumber = parseInt(step, 10);
    const { transition: { description, subTitle } } = getExerciseInfos(exerciseNumber);

    const confirmRestart = (stepNumber, title, text = undefined) => {
        confirmAlert({
            /* eslint-disable-next-line */
            customUI: ({ onClose }) => (
                <Modal closeModal={onClose} title={title}>
                    {text && <p>{text}</p>}
                    <div className="buttons">
                        <Button
                            className="button--grey button--block base-margin"
                            onClick={onClose}
                            text={t('common.no')}
                        />
                        <Button
                            className="button--black button--block base-margin"
                            onClick={async () => {
                                await dispatch(deleteResponseFromExercise(lastFormId, stepNumber));
                                push(`/step/${stepNumber}`);
                                onClose();
                            }}
                            text={t('common.start_again_exercise')}
                        />
                    </div>
                </Modal>
            ),
        });
    };

    return (
        <Container>
            <Panel><DescriptionInterstep /></Panel>
            <MainWithStep
                className="step-transition"
                footer={(
                    <>
                        { exerciseNumber > 0 && (
                            <Button
                                className="button--outline button--block-mobile"
                                text={t('exercise.transition.restart')}
                                onClick={() => confirmRestart(
                                    1,
                                    t('exercise.transition.restart.modal.title'),
                                )}
                            />
                        )}
                        <Button
                            className="button--black button--block-mobile"
                            text={t('exercise.transition.continue')}
                            onClick={() => push(`/step/${exerciseNumber + 1}`)}
                        />
                    </>
                )}
                subtitle={description && (
                    <>
                        <Trans i18nKey={description} />
                        <p>{t('exercise.transition.description.continue')}</p>
                    </>
                )}
                title={subTitle && t(subTitle)}
            >
                <StepList
                    currentStep={exerciseNumber}
                    onRestart={(stepNumber) => confirmRestart(
                        stepNumber,
                        t('exercise.start_again.modal.title'),
                        <Trans i18nKey="exercise.start_again.modal.content" />,
                    )}
                />
            </MainWithStep>
        </Container>
    );
};

StepTransition.propTypes = {
    lastFormId: PropTypes.string.isRequired,
};

export default compose(requireLastFormId)(StepTransition);
