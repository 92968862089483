import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// we can safely allow the dev dependency for deepForceUpdate
// on production, webpack will nullify it
// eslint-disable-next-line import/no-extraneous-dependencies
import deepForceUpdate from 'react-deep-force-update';
import App from './App';

import {
    checkCookieConsent,
    getCookieLanguage,
    loadReactGA,
    setAxeptionSettings,
    setOnConsentChange,
} from './utils/cookies';
import webviewDetection from './utils/webviewDetection';

import './i18n';

// get the container
const container = document.getElementById('app');

// get app data sent by the back
const appData = JSON.parse(container.dataset.app);

Sentry.init({
    dsn: appData.sentryDsn,
    environment: appData.sentryEnv,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
});

// Initialize GA only is the user consent to the use of its data
if (!webviewDetection()) {
    setAxeptionSettings(appData.COOKIE_CLIENT_ID, getCookieLanguage());
}

(function (d, s) {
    const t = d.getElementsByTagName(s)[0];
    const e = d.createElement(s);
    e.async = true; e.src = '//static.axept.io/sdk-slim.js';
    t.parentNode.insertBefore(e, t);
}(document, 'script'));

setOnConsentChange();

if (checkCookieConsent()) {
    loadReactGA(appData.googleAnalyticsTag);
}

let appInstance = null;

// Re-render the app when window.location changes
const renderApp = () => {
    try {
        let appElement = <App appData={appData} />;

        if (!__DEV__) {
            appElement = (
                <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
                    {appElement}
                </Sentry.ErrorBoundary>
            );
        }

        // render it
        appInstance = render(appElement, container);
    } catch (error) {
        if (__DEV__) {
            throw error;
        }

        console.error(error);
    }
};

renderApp();

// Enable Hot Module Replacement (HMR)
if (module.hot) {
    module.hot.accept('./App', () => {
        if (appInstance && appInstance.updater.isMounted(appInstance)) {
            // Force-update the whole tree, including components that refuse to update
            deepForceUpdate(appInstance);
        }

        renderApp();
    });
}
