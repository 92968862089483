import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../common/Modal';

const Instructions = ({ children, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal closeModal={onClose} title={t('exercise.instruction.title')} fullscreen>
            <div className="base-margin">
                {children}
                <button
                    className="base-margin button button--black button--block"
                    onClick={onClose}
                    type="button"
                >
                    {t('exercise.instruction.start')}
                </button>
            </div>
        </Modal>
    );
};

Instructions.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func,
};

export default Instructions;
