import React, { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { STATUS_ADMIN } from '../../../../constants';
import { deleteAccount as deleteAccountAction } from '../../../actions';
import useLogout from '../../../hooks/useLogout';
import { useMenu } from '../../../hooks/useMenu';
import LanguageContext from '../../../Language/LanguageContext';
import DeleteAccountModal from '../../DeleteAccountModal';

import './style.scss';

const SecondaryMenu = () => {
    const { t } = useTranslation();
    const { openMenu, menuIsOpen } = useMenu();
    const logout = useLogout();
    const dispatch = useDispatch();
    const { auth: { status }, user } = useSelector((state) => state);
    const { language } = useContext(LanguageContext);

    const showError = () => {
        // eslint-disable-next-line no-alert
        window.alert(t('error.cannot_delete_account'));
    };

    const deleteAccount = async () => {
        ReactGA.event({
            category: 'user-action',
            action: 'delete-account',
        });
        const deletionIsSuccessfull = await dispatch(deleteAccountAction());
        if (deletionIsSuccessfull) {
            logout();
        } else {
            showError();
        }
    };

    const onDeleteAccount = () => {
        confirmAlert({
            /* eslint-disable-next-line */
            customUI: ({ onClose }) => (
                <DeleteAccountModal onClose={onClose} onConfirm={deleteAccount} />
            ),
        });
    };

    return (
        <ul className="secondary-menu">
            {(menuIsOpen && user) ? (
                <>
                    <li className="secondary-menu__link">
                        <button
                            onClick={() => { openMenu(false); logout(); }}
                            type="button"
                        >
                            {t('account.card.logout')}
                        </button>
                    </li>
                    { status !== STATUS_ADMIN && (
                        <li className="secondary-menu__link">
                            <button
                                onClick={() => { openMenu(false); onDeleteAccount(); }}
                                type="button"
                            >
                                {t('account.card.delete_account')}
                            </button>
                        </li>
                    )}
                </>
            ) : (
                <>
                    { status !== STATUS_ADMIN && (
                        <>
                            <li className="secondary-menu__link">
                                <Link
                                    onClick={() => { openMenu(false); }}
                                    title={t('common.footer.legal_mentions')}
                                    to={`/${language}/legalnotices`}
                                >
                                    {t('common.footer.legal_mentions')}
                                </Link>
                            </li>
                            <li className="secondary-menu__link">
                                <Link
                                    onClick={() => { openMenu(false); }}
                                    title={t('common.terms_of_service')}
                                    to={`/${language}/tos`}
                                >
                                    {t('common.terms_of_service')}
                                </Link>
                            </li>
                            <li className="secondary-menu__link">
                                <Link
                                    onClick={() => { openMenu(false); }}
                                    title={t('common.footer.privacy_policy')}
                                    to={`/${language}/confidentiality`}
                                >
                                    {t('common.footer.privacy_policy')}
                                </Link>
                            </li>
                        </>
                    )}
                </>
            )}
        </ul>
    );
};

export default SecondaryMenu;
