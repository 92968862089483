import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateUserLanguage } from '../../../actions';
import useUserLanguage from '../../../hooks/useUserLanguage';
import LanguageContext from '../../../Language/LanguageContext';
import { getAllLangs } from '../../../utils/lang';
import SelectField from '../../Form/SelectField';

import './style.scss';

const LanguageSwitcher = ({ className, color = 'white', label, shortLabel = false }) => {
    const dispatch = useDispatch();
    const userLanguage = useUserLanguage();
    const { push } = useHistory();
    const languages = getAllLangs();
    const { language, allowedLanguages, setLanguage } = useContext(LanguageContext);

    let defaultValue = language;
    if (userLanguage) {
        defaultValue = allowedLanguages.includes(userLanguage) ? userLanguage : undefined;
    }

    const onChangeLanguage = (newLanguage) => {
        if (userLanguage) {
            dispatch(updateUserLanguage({ language: newLanguage }));
        } else {
            setLanguage(newLanguage);
            push(`/${newLanguage}`);
        }
    };

    return (
        <div className={`${className} language-switcher language-switcher--${color}`}>
            <SelectField
                input={{
                    name: 'userLanguage',
                    onChange: (value) => onChangeLanguage(value),
                    value: defaultValue,
                }}
                label={label}
                labelHidden={!label}
                menuPlacement={shortLabel ? 'top' : 'bottom'}
                options={languages.map(({ keyLang, label: labelLng }) => ({
                    value: keyLang,
                    label: shortLabel ? keyLang.toUpperCase() : labelLng,
                }))}
            />
        </div>
    );
};

LanguageSwitcher.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['black', 'white']),
    label: PropTypes.string,
    shortLabel: PropTypes.bool,
};

export default LanguageSwitcher;
