import { mergeRight } from 'ramda';
import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import auth from '../jt-client/reducers/auth';
import reconnect from './reconnect';
import user from './user';

const rootReducer = combineReducers({
    auth,
    reconnect,
    form: reduxFormReducer.plugin({
        personalityForm: (state, { type, ...payload }) => {
            switch (type) {
                case 'SET_VALUES':
                    return { ...state, ...mergeRight(state, payload) };
                default:
                    return state;
            }
        },
    }),
    user,
});

export default rootReducer;
