import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DescriptionExercise from '../DescriptionExercise';
import './style.scss';

const NB_STEP = 6;

const generateStep = ({ step, currentStep }) => {
    const hiddenDescription = step !== currentStep;

    return (
        <div className={classnames('step-list__step', { 'step-list__step--hidden': hiddenDescription })}>
            <DescriptionExercise hiddenDescription={hiddenDescription} step={step} type="short" />
        </div>
    );
};

generateStep.propTypes = {
    step: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
};

const StepList = ({ step }) => {
    const steps = [];
    for (let i = 1; i <= NB_STEP; i++) {
        steps.push(generateStep({ step: i, currentStep: step }));
    }

    return <div className="step-list">{steps}</div>;
};

StepList.propTypes = {
    step: PropTypes.number.isRequired,
};

export default StepList;
