import langConfig from '../translations/_lang.json';

export const DEFAULT_MISSING_PAGE_LANGUAGE = 'en';

export const DEFAULT_LANGUAGE = 'en';

export const getAllLangs = () => langConfig.languages.sort((a, b) => (a.label < b.label ? -1 : 1));

export const getLangInfos = (key) => langConfig.languages.find((l) => l.keyLang === key);

export const isAValidLanguage = (lang) => langConfig.languages.find((l) => l.keyLang === lang) !== undefined;

export const extractLangFromIso639 = (isoCode) => isoCode.split('-')[0];

export const getAllLangKeys = () => langConfig.languages.map((l) => l.keyLang);

// handle accents
export const normalizedStr = (str) => str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
