import { normalizedStr } from './lang';

const getBranchesRanking = (branches, maxBranches) => {
    const branchesArray = Object.keys(branches).map((branchKey) => [branchKey, branches[branchKey]]);
    branchesArray.sort((a, b) => (b[1] - a[1]
        || normalizedStr(a[0]).localeCompare(normalizedStr(b[0]))));
    const rankingArray = 0 === maxBranches ? branchesArray : branchesArray.slice(0, maxBranches);

    return rankingArray.reduce((rankingObject, [branchKey, value]) => ({ ...rankingObject, [branchKey]: value }), {});
};

export default getBranchesRanking;
