import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppData } from '../../hooks/useAppData';
import './style.scss';

const SsoBanner = () => {
    const { t } = useTranslation();
    const { FREYA_URL, HESTER_URL, VASCO_V2_URL } = useAppData();

    return (
        <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('home.sso_banner', { FREYA_URL, HESTER_URL, VASCO_V2_URL }) }}
            className="subtitle home-sso-banner"
        />
    );
};

export default SsoBanner;
