import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLastForm } from '../../actions';
import { createForm, getLastForms, resetForm } from '../../actions/personalityForm';
import Button from '../../components/common/Button';
import Container from '../../components/layout/Container';
import Main from '../../components/layout/Main';
import Panel from '../../components/layout/Panel';
import DescriptionAccount from '../../components/PanelDescription/DescriptionAccount';
import useLocalizedHistoryPush from '../../hooks/useLocalizedHistoryPush';
import { previousStepURL } from '../../utils/menu';
import './style.scss';

const Account = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();
    const { reconnect: { lastForm } } = useSelector((state) => state);

    useEffect(() => {
        dispatch(getLastForms());
    }, []);

    const startNewForm = async () => {
        await dispatch(deleteLastForm());
        await dispatch(resetForm());
        await dispatch(createForm());

        push('/step/1');
    };

    return (
        <Container>
            <Panel><DescriptionAccount /></Panel>
            <Main className="account__main">
                <h1 className="title"><Trans i18nKey="account.card.title" /></h1>
                <p className="account__instructions"><Trans i18nKey="account.card.text" /></p>
                <div className="main__buttons">
                    {(lastForm && !lastForm.completedAt) && (
                        <Button
                            className="button--outline"
                            onClick={() => {
                                push(previousStepURL({ form: lastForm }));
                            }}
                            text={t('account.card.continue')}
                        />
                    )}
                    <Button
                        className="button--black"
                        onClick={startNewForm}
                        text={t('account.card.start')}
                    />
                </div>
            </Main>
        </Container>
    );
};

export default Account;
