export const AGRICULTURE_FISHING = 'agriculture_fishing';
export const ANIMALS = 'animals';
export const ARCHITECTURE_INTERIOR_DESIGN = 'architecture_interior_design';
export const ARTS_CRAFTS = 'arts_crafts';
export const BANK_FINANCE_AUDIT_MANAGEMENT = 'bank_finance_audit_management';
export const BIOLOGY_CHEMISTRY = 'biology_chemistry';
export const BUILDINGS_PUBLIC_WORKS = 'buildings_public_works';
export const CARE_AESTHETICS_HAIRDRESSING = 'care_aesthetics_hairdressing';
export const COMMUNICATION_INFORMATION = 'communication_information';
export const COMPUTER_SCIENCE_ELECTRONICS = 'computer_science_electronics';
export const CULTURE_SHOW = 'culture_show';
export const DEFENSE_SECURITY_RESCUE = 'defense_security_rescue';
export const DIGITAL_MULTIMEDIA_AUDIOVISUAL = 'digital_multimedia_audiovisual';
export const EDUCATION_TRAINING = 'education_training';
export const ENVIRONMENT_NATURE_CLEANING = 'environment_nature_cleaning';
export const FOOD_FOOD_INDUSTRY = 'food_food_industry';
export const HEALTH = 'health';
export const HOTEL_CATERING_TOURISM = 'hotel_catering_tourism';
export const HUMANITARIAN = 'humanitarian';
export const HUMANITIES = 'humanities'; // études de lettre et sciences humaines
export const HUMAN_RESSOURCES = 'human_ressources';
export const INDUSTRY_MATERIALS = 'industry_materials';
export const LAW_POLITICS = 'law_politics';
export const MECHANICS_MAINTENANCE = 'mechanics_maintenance';
export const PUBLISHING_PRINTING_BOOKS = 'publishing_printing_books';
export const SCIENCES_MATHEMATICS_PHYSICS = 'sciences_mathematics_physics';
export const SECRETARIAT_RECEPTION = 'secretariat_reception';
export const SOCIAL_PERSONAL_SERVICES = 'social_personal_services';
export const SPORT_ANIMATION = 'sport_animation';
export const TRADE_REAL_ESTATE_INSURANCE = 'trade_real_estate_insurance';
export const TRANSPORTATION_LOGISTICS = 'transportation_logistics';
