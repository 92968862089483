import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';

const HomeHeaderMenu = ({
    className = '',
    leftElement,
    rightElement,
}) => (
    <div className={`header-menu__wrapper-menu ${className}`}>
        <div className="header-menu__inner">
            { leftElement }
            { rightElement }
        </div>
    </div>
);

HomeHeaderMenu.propTypes = {
    className: PropTypes.string,
    leftElement: PropTypes.node,
    rightElement: PropTypes.node,
};

export default HomeHeaderMenu;
