import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { getExerciseFirstQuestionNumber } from '../../utils/exercises';

const ExerciseRedirector = ({ match: { params: { step } } }) => {
    const firstExerciseQuestion = getExerciseFirstQuestionNumber(parseInt(step, 10));

    return <Redirect to={`/step/${step}/${firstExerciseQuestion}`} />;
};

ExerciseRedirector.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            step: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default ExerciseRedirector;
