import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';

const Spinner = ({ color }) => (
    <div className={`lds-ellipsis ${color ? `lds-ellipsis--${color}` : ''}`}>
        <div />
        <div />
        <div />
        <div />
    </div>
);

Spinner.propTypes = {
    color: PropTypes.string,
};

export default Spinner;
