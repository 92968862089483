import PropTypes from 'prop-types';

export default (inputValue) => ({
    className: PropTypes.string,
    input: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
        value: inputValue,
    }),
    label: PropTypes.string,
    labelHidden: PropTypes.bool,
    meta: PropTypes.shape({
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
        warning: PropTypes.string,
    }),
    placeholder: PropTypes.string,
});
