import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './style.scss';

const FrontPage = ({ date, user: { name, surname } }) => {
    const { t } = useTranslation();

    return (
        <div className="pdf-front-page__container">
            <div className="pdf-front-page__content">
                <div className="pdf-front-page__header">
                    <img
                        alt="Jobteaser logo"
                        className="jobteaser-logo"
                        src="/img/logos/jobteaser-black.svg"
                    />
                    <img
                        alt="First page shape"
                        src="/img/pdf/pdf-home-shape.svg"
                    />
                </div>
                <div className="pdf-front-page__text-container">
                    <h1>{`${surname} ${name}`}</h1>
                    <span className="date">{date}</span>
                </div>
                <div className="pdf-front-page__hester-logo-container">
                    <img
                        alt="Hester logo"
                        src="/img/pdf/pdf-marco-logo.svg"
                    />
                </div>
            </div>
            <div className="pdf-front-page__footer">
                <span>{t('pdf.frontPage.footer')}</span>
            </div>
        </div>
    );
};

FrontPage.propTypes = {
    date: PropTypes.string.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
    }).isRequired,
};

export default FrontPage;
