import { useTranslation } from 'react-i18next';
import * as Branches from '../../constants/branches';
import { normalizedStr } from '../utils/lang';

const useOrderedTranslatedBranches = () => {
    const { t } = useTranslation();

    const { translatedBranches, technicalKeys } = Object.keys(Branches).reduce((acc, branchKey) => {
        const branchValue = Branches[branchKey];
        const { translatedBranches: branches, technicalKeys: keys } = acc;
        const translatedBranch = t(`common.branch.${branchValue}`);

        return {
            translatedBranches: [...branches, translatedBranch],
            technicalKeys: { ...keys, [translatedBranch]: branchValue },
        };
    }, { translatedBranches: [], technicalKeys: {} });

    return {
        translatedBranches: translatedBranches.sort((a, b) => (normalizedStr(a) > normalizedStr(b) ? 1 : -1)),
        branchTechnicalKeys: technicalKeys,
    };
};

export default useOrderedTranslatedBranches;
