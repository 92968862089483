import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { STATUS_ADMIN } from '../../../../constants';
import useLocalizedHistoryPush from '../../../hooks/useLocalizedHistoryPush';
import { useMenu } from '../../../hooks/useMenu';
import { previousStepURL } from '../../../utils/menu';
import LanguageSwitcher from '../../common/LanguageSwitcher';
import './style.scss';

const Menu = () => {
    const { t } = useTranslation();
    const { openMenu } = useMenu();
    const push = useLocalizedHistoryPush();
    const { auth: { status }, user, reconnect: { lastForm, lastCompletedForm } } = useSelector((state) => state);

    return (
        <div className="menu">
            <h1 className="menu__title">{t('account.welcome', { username: user && user.surname })}</h1>
            { user && status !== STATUS_ADMIN && <LanguageSwitcher color="black" /> }
            <ul className="menu__inner">
                { status !== STATUS_ADMIN && (
                    <>
                        <li className="menu__link">
                            <button type="button" onClick={() => push('/account')} className="marco-btn">
                                <img
                                    alt={t('menu.account')}
                                    src="/img/icons/house.svg"
                                />
                                {t('menu.account')}
                            </button>
                        </li>
                        { lastForm && !lastForm.completedAt && (
                            <li className="menu__link">
                                <Link
                                    onClick={() => { openMenu(false); }}
                                    title={t('menu.continue')}
                                    to={previousStepURL({ form: lastForm })}
                                >
                                    <img
                                        alt={t('menu.continueLastForm')}
                                        src="/img/icons/pen.svg"
                                    />
                                    {t('menu.continueLastForm')}
                                </Link>
                            </li>
                        )}
                        { lastCompletedForm && lastCompletedForm.completedAt && (
                            <li className="menu__link">
                                <Link
                                    onClick={() => { openMenu(false); }}
                                    title={t('menu.results')}
                                    to={`/results/${lastCompletedForm.id}`}
                                >
                                    <img
                                        alt={t('menu.lastResults')}
                                        src="/img/icons/statistic.svg"
                                    />
                                    {t('menu.lastResults')}
                                </Link>
                            </li>
                        )}
                    </>
                )}
            </ul>
        </div>
    );
};

export default Menu;
