import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppData } from '../../../hooks/useAppData';
import './style.scss';

const Field = ({
    className = '',
    input,
    label,
    labelHidden = false,
    placeholder,
    type,
    meta: { touched, error, warning },
    ...props
}) => {
    const { VASCO_V2_URL } = useAppData();
    const { t } = useTranslation();

    let err = error;
    let warn = warning;
    let warnValues = {};

    if ('emailAlreadyUsed' === error) {
        err = undefined;
        warn = 'error.email.already_used';
        warnValues = { VASCO_V2_URL };
    }

    return (
        <div
            className={`
                field
                ${touched && err ? 'field--error' : ''}
                ${touched && warn ? 'field--warning' : ''}
                ${className}
            `}
        >
            {label && <label className={labelHidden ? 'field__label--hidden' : ''} htmlFor={input.name}>{label}</label>}
            <input
                {...input}
                {...props}
                id={input.name}
                placeholder={placeholder}
                type={type}
            />
            {touched
                && ((err && <span>{t(err)}</span>)
                    // eslint-disable-next-line react/no-danger
                    || (warn && <span dangerouslySetInnerHTML={{ __html: t(warn, warnValues) }} />))}
        </div>
    );
};

Field.propTypes = {
    className: PropTypes.string,
    input: PropTypes.shape({
        name: PropTypes.string,
    }),
    label: PropTypes.string,
    labelHidden: PropTypes.bool,
    meta: PropTypes.shape({
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
        warning: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
};

export default Field;
