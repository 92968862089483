import {
    AGRICULTURE_FISHING,
    ANIMALS,
    ARCHITECTURE_INTERIOR_DESIGN,
    ARTS_CRAFTS,
    BANK_FINANCE_AUDIT_MANAGEMENT,
    BIOLOGY_CHEMISTRY,
    BUILDINGS_PUBLIC_WORKS,
    CARE_AESTHETICS_HAIRDRESSING,
    COMMUNICATION_INFORMATION,
    COMPUTER_SCIENCE_ELECTRONICS,
    CULTURE_SHOW,
    DEFENSE_SECURITY_RESCUE,
    DIGITAL_MULTIMEDIA_AUDIOVISUAL,
    EDUCATION_TRAINING,
    ENVIRONMENT_NATURE_CLEANING,
    FOOD_FOOD_INDUSTRY,
    HEALTH,
    HOTEL_CATERING_TOURISM,
    HUMAN_RESSOURCES,
    HUMANITARIAN,
    HUMANITIES,
    INDUSTRY_MATERIALS,
    LAW_POLITICS,
    MECHANICS_MAINTENANCE,
    PUBLISHING_PRINTING_BOOKS,
    SCIENCES_MATHEMATICS_PHYSICS,
    SECRETARIAT_RECEPTION,
    SOCIAL_PERSONAL_SERVICES,
    SPORT_ANIMATION,
    TRADE_REAL_ESTATE_INSURANCE,
    TRANSPORTATION_LOGISTICS,
} from './branches';

import { C, I, O, P, S } from './psico_profiles';

const Texts = {
    step1: {
        instruction: 'exercise.1.instruction',
        stepName: 'exercise.1.stepName',
        topText: 'exercise.1.topText',
        botText: 'exercise.1.botText',
        introPicture: 'work-style.svg',
        transition: {
            subTitle: 'exercise.1.transition.subTitle',
            description: 'exercise.1.transition.description',
        },
        progression: [11, 11, 11, 10, 10, 10],
        questions: [
            { question: 'question.1', key: 1, branch: BIOLOGY_CHEMISTRY, profile: S },
            { question: 'question.2', key: 2, branch: AGRICULTURE_FISHING, profile: P },
            { question: 'question.3', key: 3, branch: FOOD_FOOD_INDUSTRY },
            { question: 'question.4', key: 4, branch: LAW_POLITICS },
            { question: 'question.5', key: 5, branch: BUILDINGS_PUBLIC_WORKS },
            { question: 'question.6', key: 6, branch: TRADE_REAL_ESTATE_INSURANCE, profile: O },
            { question: 'question.7', key: 7, branch: INDUSTRY_MATERIALS },
            { question: 'question.8', key: 8, branch: COMMUNICATION_INFORMATION },
            { question: 'question.9', key: 9, branch: HEALTH, profile: C },
            { question: 'question.10', key: 10, branch: BIOLOGY_CHEMISTRY },
            { question: 'question.11', key: 11, branch: SPORT_ANIMATION, profile: P },
            { question: 'question.12', key: 12, branch: TRANSPORTATION_LOGISTICS },
            { question: 'question.13', key: 13, branch: SOCIAL_PERSONAL_SERVICES },
            { question: 'question.14', key: 14, branch: DIGITAL_MULTIMEDIA_AUDIOVISUAL },
            { question: 'question.15', key: 15, branch: SCIENCES_MATHEMATICS_PHYSICS, profile: S },
            { question: 'question.16', key: 16, branch: LAW_POLITICS },
            { question: 'question.17', key: 17, branch: ARCHITECTURE_INTERIOR_DESIGN },
            { question: 'question.18', key: 18, branch: ENVIRONMENT_NATURE_CLEANING },
            { question: 'question.19', key: 19, branch: HUMAN_RESSOURCES, profile: O },
            { question: 'question.20', key: 20, branch: ARCHITECTURE_INTERIOR_DESIGN },
            { question: 'question.21', key: 21, branch: HUMAN_RESSOURCES },
            { question: 'question.22', key: 22, branch: SECRETARIAT_RECEPTION, profile: O },
            { question: 'question.23', key: 23, branch: HEALTH },
            { question: 'question.24', key: 24, branch: CULTURE_SHOW },
            { question: 'question.25', key: 25, branch: PUBLISHING_PRINTING_BOOKS },
            { question: 'question.26', key: 26, branch: HUMANITIES, profile: I },
            { question: 'question.27', key: 27, branch: COMMUNICATION_INFORMATION, profile: O },
            { question: 'question.28', key: 28, branch: DIGITAL_MULTIMEDIA_AUDIOVISUAL },
            { question: 'question.29', key: 29, branch: HUMANITIES },
            { question: 'question.30', key: 30, branch: HUMAN_RESSOURCES },
            { question: 'question.31', key: 31, branch: ENVIRONMENT_NATURE_CLEANING },
            { question: 'question.32', key: 32, branch: HUMANITIES },
            { question: 'question.33', key: 33, branch: TRADE_REAL_ESTATE_INSURANCE },
            { question: 'question.34', key: 34, branch: ANIMALS },
            { question: 'question.35', key: 35, branch: HEALTH },
            { question: 'question.36', key: 36, branch: COMPUTER_SCIENCE_ELECTRONICS },
            { question: 'question.37', key: 37, branch: BANK_FINANCE_AUDIT_MANAGEMENT },
            { question: 'question.38', key: 38, branch: CULTURE_SHOW, profile: I },
            { question: 'question.39', key: 39, branch: SECRETARIAT_RECEPTION },
            { question: 'question.40', key: 40, branch: CARE_AESTHETICS_HAIRDRESSING },
            { question: 'question.41', key: 41, branch: FOOD_FOOD_INDUSTRY },
            { question: 'question.42', key: 42, branch: MECHANICS_MAINTENANCE },
            { question: 'question.43', key: 43, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.44', key: 44, branch: SOCIAL_PERSONAL_SERVICES, profile: C },
            { question: 'question.45', key: 45, branch: MECHANICS_MAINTENANCE },
            { question: 'question.46', key: 46, branch: HOTEL_CATERING_TOURISM },
            { question: 'question.47', key: 47, branch: DIGITAL_MULTIMEDIA_AUDIOVISUAL, profile: I },
            { question: 'question.48', key: 48, branch: BANK_FINANCE_AUDIT_MANAGEMENT },
            { question: 'question.49', key: 49, branch: AGRICULTURE_FISHING },
            { question: 'question.50', key: 50, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.51', key: 51, branch: EDUCATION_TRAINING },
            { question: 'question.52', key: 52, branch: SCIENCES_MATHEMATICS_PHYSICS },
            { question: 'question.53', key: 53, branch: BIOLOGY_CHEMISTRY },
            { question: 'question.54', key: 54, branch: PUBLISHING_PRINTING_BOOKS },
            { question: 'question.55', key: 55, branch: TRANSPORTATION_LOGISTICS },
            { question: 'question.56', key: 56, branch: COMPUTER_SCIENCE_ELECTRONICS, profile: S },
            { question: 'question.57', key: 57, branch: TRADE_REAL_ESTATE_INSURANCE, profile: O },
            { question: 'question.58', key: 58, branch: SCIENCES_MATHEMATICS_PHYSICS, profile: S },
            { question: 'question.59', key: 59, branch: INDUSTRY_MATERIALS },
            { question: 'question.60', key: 60, branch: HUMANITARIAN },
            { question: 'question.61', key: 61, branch: ARTS_CRAFTS },
            { question: 'question.62', key: 62, branch: ANIMALS },
            { question: 'question.63', key: 63, branch: COMMUNICATION_INFORMATION, profile: O },
        ],
    },
    step2: {
        instruction: 'exercise.2.instruction',
        stepName: 'exercise.2.stepName',
        topText: 'exercise.2.topText',
        botText: 'exercise.2.botText',
        introPicture: 'work-style.svg',
        transition: {
            subTitle: 'exercise.2.transition.subTitle',
            description: 'exercise.2.transition.description',
        },
        progression: [6, 6, 6, 6, 6, 6],
        questions: [
            { question: 'question.64', key: 64, branch: BANK_FINANCE_AUDIT_MANAGEMENT },
            { question: 'question.65', key: 65, branch: HOTEL_CATERING_TOURISM },
            { question: 'question.66', key: 66, branch: CULTURE_SHOW },
            { question: 'question.67', key: 67, branch: LAW_POLITICS },
            { question: 'question.68', key: 68, branch: ENVIRONMENT_NATURE_CLEANING },
            { question: 'question.69', key: 69, branch: ARCHITECTURE_INTERIOR_DESIGN },
            { question: 'question.70', key: 70, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.71', key: 71, branch: ARTS_CRAFTS, profile: I },
            { question: 'question.72', key: 72, branch: AGRICULTURE_FISHING },
            { question: 'question.73', key: 73, branch: SOCIAL_PERSONAL_SERVICES },
            { question: 'question.74', key: 74, branch: FOOD_FOOD_INDUSTRY },
            { question: 'question.75', key: 75, branch: ARTS_CRAFTS, profile: I },
            { question: 'question.76', key: 76, branch: CULTURE_SHOW, profile: I },
            { question: 'question.77', key: 77, branch: INDUSTRY_MATERIALS },
            { question: 'question.78', key: 78, branch: ARCHITECTURE_INTERIOR_DESIGN },
            { question: 'question.79', key: 79, branch: ENVIRONMENT_NATURE_CLEANING },
            { question: 'question.80', key: 80, branch: MECHANICS_MAINTENANCE },
            { question: 'question.81', key: 81, branch: SOCIAL_PERSONAL_SERVICES },
            { question: 'question.82', key: 82, branch: HUMANITIES, profile: I },
            { question: 'question.83', key: 83, branch: HOTEL_CATERING_TOURISM },
            { question: 'question.84', key: 84, branch: PUBLISHING_PRINTING_BOOKS },
            { question: 'question.85', key: 85, branch: HEALTH },
            { question: 'question.86', key: 86, branch: HUMAN_RESSOURCES },
            { question: 'question.87', key: 87, branch: BUILDINGS_PUBLIC_WORKS },
            { question: 'question.88', key: 88, branch: LAW_POLITICS },
            { question: 'question.89', key: 89, branch: COMPUTER_SCIENCE_ELECTRONICS },
            { question: 'question.90', key: 90, branch: EDUCATION_TRAINING },
            { question: 'question.91', key: 91, branch: SPORT_ANIMATION },
            { question: 'question.92', key: 92, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.93', key: 93, branch: BUILDINGS_PUBLIC_WORKS },
            { question: 'question.94', key: 94, branch: HUMAN_RESSOURCES },
            { question: 'question.95', key: 95, branch: BANK_FINANCE_AUDIT_MANAGEMENT },
            { question: 'question.96', key: 96, branch: COMMUNICATION_INFORMATION },
            { question: 'question.97', key: 97, branch: TRADE_REAL_ESTATE_INSURANCE },
            { question: 'question.98', key: 98, branch: HUMANITARIAN, profile: C },
            { question: 'question.99', key: 99, branch: HEALTH, profile: C },
        ],
    },
    step3: {
        instruction: 'exercise.3.instruction',
        stepName: 'exercise.3.stepName',
        topText: 'exercise.3.topText',
        botText: 'exercise.3.botText',
        introPicture: 'work-style.svg',
        transition: {
            subTitle: 'exercise.3.transition.subTitle',
            description: 'exercise.3.transition.description',
        },
        progression: [6, 6, 6, 5, 5, 5],
        questions: [
            { question: 'question.100', key: 100, branch: SPORT_ANIMATION },
            { question: 'question.101', key: 101, branch: MECHANICS_MAINTENANCE, profile: P },
            { question: 'question.102', key: 102, branch: BUILDINGS_PUBLIC_WORKS, profile: P },
            { question: 'question.103', key: 103, branch: INDUSTRY_MATERIALS, profile: P },
            { question: 'question.104', key: 104, branch: FOOD_FOOD_INDUSTRY },
            { question: 'question.105', key: 105, branch: ARTS_CRAFTS, profile: I },
            { question: 'question.106', key: 106, branch: CULTURE_SHOW, profile: I },
            { question: 'question.107', key: 107, branch: CARE_AESTHETICS_HAIRDRESSING },
            { question: 'question.108', key: 108, branch: ARTS_CRAFTS },
            { question: 'question.109', key: 109, branch: CULTURE_SHOW },
            { question: 'question.110', key: 110, branch: SCIENCES_MATHEMATICS_PHYSICS, profile: S },
            { question: 'question.111', key: 111, branch: HUMANITIES },
            { question: 'question.112', key: 112, branch: ANIMALS },
            { question: 'question.113', key: 113, branch: CULTURE_SHOW },
            { question: 'question.114', key: 114, branch: COMPUTER_SCIENCE_ELECTRONICS },
            { question: 'question.115', key: 115, branch: SCIENCES_MATHEMATICS_PHYSICS, profile: S },
            { question: 'question.116', key: 116, branch: LAW_POLITICS },
            { question: 'question.117', key: 117, branch: LAW_POLITICS },
            { question: 'question.118', key: 118, branch: AGRICULTURE_FISHING },
            { question: 'question.119', key: 119, branch: BANK_FINANCE_AUDIT_MANAGEMENT },
            { question: 'question.120', key: 120, branch: HUMANITARIAN, profile: C },
            { question: 'question.121', key: 121, branch: COMMUNICATION_INFORMATION },
            { question: 'question.122', key: 122, branch: HUMANITIES, profile: I },
            { question: 'question.123', key: 123, branch: MECHANICS_MAINTENANCE },
            { question: 'question.124', key: 124, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.125', key: 125, branch: ENVIRONMENT_NATURE_CLEANING },
            { question: 'question.126', key: 126, branch: FOOD_FOOD_INDUSTRY },
            { question: 'question.127', key: 127, branch: HUMANITARIAN },
            { question: 'question.128', key: 128, branch: SOCIAL_PERSONAL_SERVICES },
            { question: 'question.129', key: 129, branch: ENVIRONMENT_NATURE_CLEANING },
            { question: 'question.130', key: 130, branch: SPORT_ANIMATION, profile: P },
            { question: 'question.131', key: 131, branch: PUBLISHING_PRINTING_BOOKS },
            { question: 'question.132', key: 132, branch: TRANSPORTATION_LOGISTICS },
        ],
    },
    step4: {
        instruction: 'exercise.4.instruction',
        stepName: 'exercise.4.stepName',
        topText: 'exercise.4.topText',
        botText: 'exercise.4.botText',
        introPicture: 'work-style.svg',
        transition: {
            subTitle: 'exercise.4.transition.subTitle',
            description: 'exercise.4.transition.description',
        },
        progression: [7, 7, 6, 6, 6, 6],
        questions: [
            { question: 'question.133', key: 133, branch: CULTURE_SHOW, profile: I },
            { question: 'question.134', key: 134, branch: INDUSTRY_MATERIALS },
            { question: 'question.135', key: 135, branch: FOOD_FOOD_INDUSTRY },
            { question: 'question.136', key: 136, branch: BANK_FINANCE_AUDIT_MANAGEMENT },
            { question: 'question.137', key: 137, branch: ARTS_CRAFTS },
            { question: 'question.138', key: 138, branch: EDUCATION_TRAINING },
            { question: 'question.139', key: 139, branch: LAW_POLITICS },
            { question: 'question.140', key: 140, branch: BUILDINGS_PUBLIC_WORKS, profile: P },
            { question: 'question.141', key: 141, branch: HOTEL_CATERING_TOURISM },
            { question: 'question.142', key: 142, branch: TRADE_REAL_ESTATE_INSURANCE },
            { question: 'question.143', key: 143, branch: CARE_AESTHETICS_HAIRDRESSING },
            { question: 'question.144', key: 144, branch: TRANSPORTATION_LOGISTICS },
            { question: 'question.145', key: 145, branch: HEALTH, profile: C },
            { question: 'question.146', key: 146, branch: SOCIAL_PERSONAL_SERVICES, profile: C },
            { question: 'question.147', key: 147, branch: ENVIRONMENT_NATURE_CLEANING },
            { question: 'question.148', key: 148, branch: BIOLOGY_CHEMISTRY, profile: S },
            { question: 'question.149', key: 149, branch: PUBLISHING_PRINTING_BOOKS },
            { question: 'question.150', key: 150, branch: COMMUNICATION_INFORMATION },
            { question: 'question.151', key: 151, branch: BANK_FINANCE_AUDIT_MANAGEMENT },
            { question: 'question.152', key: 152, branch: TRANSPORTATION_LOGISTICS },
            { question: 'question.153', key: 153, branch: ANIMALS },
            { question: 'question.154', key: 154, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.155', key: 155, branch: ANIMALS },
            { question: 'question.156', key: 156, branch: CULTURE_SHOW },
            { question: 'question.157', key: 157, branch: MECHANICS_MAINTENANCE },
            { question: 'question.158', key: 158, branch: SPORT_ANIMATION },
            { question: 'question.159', key: 159, branch: SPORT_ANIMATION },
            { question: 'question.160', key: 160, branch: COMMUNICATION_INFORMATION },
            { question: 'question.161', key: 161, branch: LAW_POLITICS },
            { question: 'question.162', key: 162, branch: HUMAN_RESSOURCES },
            { question: 'question.163', key: 163, branch: SECRETARIAT_RECEPTION },
            { question: 'question.164', key: 164, branch: HUMANITARIAN },
            { question: 'question.165', key: 165, branch: COMPUTER_SCIENCE_ELECTRONICS },
            { question: 'question.166', key: 166, branch: CARE_AESTHETICS_HAIRDRESSING },
            { question: 'question.167', key: 167, branch: HOTEL_CATERING_TOURISM },
            { question: 'question.168', key: 168, branch: DIGITAL_MULTIMEDIA_AUDIOVISUAL },
            { question: 'question.169', key: 169, branch: HUMANITARIAN },
            { question: 'question.170', key: 170, branch: AGRICULTURE_FISHING },
        ],
    },
    step5: {
        instruction: 'exercise.5.instruction',
        stepName: 'exercise.5.stepName',
        topText: 'exercise.5.topText',
        botText: 'exercise.5.botText',
        introPicture: 'work-style.svg',
        transition: {
            subTitle: 'exercise.5.transition.subTitle',
            description: 'exercise.5.transition.description',
        },
        progression: [14, 13, 13, 13, 13, 13],
        questions: [
            { question: 'question.171', key: 171, branch: HEALTH },
            { question: 'question.172', key: 172, branch: DIGITAL_MULTIMEDIA_AUDIOVISUAL },
            { question: 'question.173', key: 173, branch: HUMANITIES },
            { question: 'question.174', key: 174, branch: BIOLOGY_CHEMISTRY, profile: S },
            { question: 'question.175', key: 175, branch: HUMANITARIAN },
            { question: 'question.176', key: 176, branch: DIGITAL_MULTIMEDIA_AUDIOVISUAL },
            { question: 'question.177', key: 177, branch: ARCHITECTURE_INTERIOR_DESIGN },
            { question: 'question.178', key: 178, branch: COMMUNICATION_INFORMATION, profile: O },
            { question: 'question.179', key: 179, branch: SOCIAL_PERSONAL_SERVICES },
            { question: 'question.180', key: 180, branch: BIOLOGY_CHEMISTRY },
            { question: 'question.181', key: 181, branch: BUILDINGS_PUBLIC_WORKS },
            { question: 'question.182', key: 182, branch: AGRICULTURE_FISHING },
            { question: 'question.183', key: 183, branch: HUMAN_RESSOURCES, profile: O },
            { question: 'question.184', key: 184, branch: HEALTH, profile: C },
            { question: 'question.185', key: 185, branch: EDUCATION_TRAINING },
            { question: 'question.186', key: 186, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.187', key: 187, branch: ARTS_CRAFTS },
            { question: 'question.188', key: 188, branch: HUMANITIES },
            { question: 'question.189', key: 189, branch: HOTEL_CATERING_TOURISM },
            { question: 'question.190', key: 190, branch: LAW_POLITICS },
            { question: 'question.191', key: 191, branch: PUBLISHING_PRINTING_BOOKS },
            { question: 'question.192', key: 192, branch: ANIMALS },
            { question: 'question.193', key: 193, branch: COMPUTER_SCIENCE_ELECTRONICS },
            { question: 'question.194', key: 194, branch: ANIMALS },
            { question: 'question.195', key: 195, branch: EDUCATION_TRAINING },
            { question: 'question.196', key: 196, branch: CULTURE_SHOW },
            { question: 'question.197', key: 197, branch: DIGITAL_MULTIMEDIA_AUDIOVISUAL },
            { question: 'question.198', key: 198, branch: TRADE_REAL_ESTATE_INSURANCE, profile: O },
            { question: 'question.199', key: 199, branch: AGRICULTURE_FISHING, profile: P },
            { question: 'question.200', key: 200, branch: HUMANITIES },
            { question: 'question.201', key: 201, branch: SCIENCES_MATHEMATICS_PHYSICS, profile: S },
            { question: 'question.202', key: 202, branch: SOCIAL_PERSONAL_SERVICES, profile: C },
            { question: 'question.203', key: 203, branch: CULTURE_SHOW },
            { question: 'question.204', key: 204, branch: BANK_FINANCE_AUDIT_MANAGEMENT },
            { question: 'question.205', key: 205, branch: COMPUTER_SCIENCE_ELECTRONICS },
            { question: 'question.206', key: 206, branch: TRANSPORTATION_LOGISTICS },
            { question: 'question.207', key: 207, branch: LAW_POLITICS },
            { question: 'question.208', key: 208, branch: SECRETARIAT_RECEPTION },
            { question: 'question.209', key: 209, branch: COMPUTER_SCIENCE_ELECTRONICS },
            { question: 'question.210', key: 210, branch: BANK_FINANCE_AUDIT_MANAGEMENT, profile: O },
            { question: 'question.211', key: 211, branch: BUILDINGS_PUBLIC_WORKS, profile: P },
            { question: 'question.212', key: 212, branch: HEALTH },
            { question: 'question.213', key: 213, branch: BANK_FINANCE_AUDIT_MANAGEMENT },
            { question: 'question.214', key: 214, branch: ARCHITECTURE_INTERIOR_DESIGN },
            { question: 'question.215', key: 215, branch: TRANSPORTATION_LOGISTICS, profile: P },
            { question: 'question.216', key: 216, branch: INDUSTRY_MATERIALS },
            { question: 'question.217', key: 217, branch: ENVIRONMENT_NATURE_CLEANING },
            { question: 'question.218', key: 218, branch: CULTURE_SHOW },
            { question: 'question.219', key: 219, branch: TRANSPORTATION_LOGISTICS },
            { question: 'question.220', key: 220, branch: TRADE_REAL_ESTATE_INSURANCE, profile: O },
            { question: 'question.221', key: 221, branch: AGRICULTURE_FISHING, profile: P },
            { question: 'question.222', key: 222, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.223', key: 223, branch: MECHANICS_MAINTENANCE },
            { question: 'question.224', key: 224, branch: ARTS_CRAFTS },
            { question: 'question.225', key: 225, branch: HEALTH },
            { question: 'question.226', key: 226, branch: FOOD_FOOD_INDUSTRY },
            { question: 'question.227', key: 227, branch: LAW_POLITICS },
            { question: 'question.228', key: 228, branch: BUILDINGS_PUBLIC_WORKS },
            { question: 'question.229', key: 229, branch: HOTEL_CATERING_TOURISM },
            { question: 'question.230', key: 230, branch: MECHANICS_MAINTENANCE, profile: P },
            { question: 'question.231', key: 231, branch: HUMAN_RESSOURCES },
            { question: 'question.232', key: 232, branch: HOTEL_CATERING_TOURISM },
            { question: 'question.233', key: 233, branch: COMPUTER_SCIENCE_ELECTRONICS, profile: S },
            { question: 'question.234', key: 234, branch: TRANSPORTATION_LOGISTICS },
            { question: 'question.235', key: 235, branch: COMMUNICATION_INFORMATION },
            { question: 'question.236', key: 236, branch: HUMANITARIAN, profile: C },
            { question: 'question.237', key: 237, branch: EDUCATION_TRAINING, profile: C },
            { question: 'question.238', key: 238, branch: SCIENCES_MATHEMATICS_PHYSICS, profile: S },
            { question: 'question.239', key: 239, branch: ENVIRONMENT_NATURE_CLEANING },
            { question: 'question.240', key: 240, branch: PUBLISHING_PRINTING_BOOKS },
            { question: 'question.241', key: 241, branch: CARE_AESTHETICS_HAIRDRESSING },
            { question: 'question.242', key: 242, branch: CARE_AESTHETICS_HAIRDRESSING },
            { question: 'question.243', key: 243, branch: SECRETARIAT_RECEPTION },
            { question: 'question.244', key: 244, branch: SPORT_ANIMATION },
            { question: 'question.245', key: 245, branch: FOOD_FOOD_INDUSTRY },
            { question: 'question.246', key: 246, branch: CARE_AESTHETICS_HAIRDRESSING },
            { question: 'question.247', key: 247, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.248', key: 248, branch: SECRETARIAT_RECEPTION },
            { question: 'question.249', key: 249, branch: HUMAN_RESSOURCES },
        ],
    },
    step6: {
        instruction: 'exercise.6.instruction',
        stepName: 'exercise.6.stepName',
        topText: 'exercise.6.topText',
        botText: 'exercise.6.botText',
        introPicture: 'work-style.svg',
        transition: {
            subTitle: 'exercise.6.transition.subTitle',
            description: 'exercise.6.transition.description',
        },
        progression: [5, 5, 5, 4, 4, 4],
        questions: [
            { question: 'question.250', key: 250, branch: BIOLOGY_CHEMISTRY },
            { question: 'question.251', key: 251, branch: DIGITAL_MULTIMEDIA_AUDIOVISUAL },
            { question: 'question.252', key: 252, branch: HUMAN_RESSOURCES },
            { question: 'question.253', key: 253, branch: ARTS_CRAFTS },
            { question: 'question.254', key: 254, branch: HUMANITIES },
            { question: 'question.255', key: 255, branch: ARCHITECTURE_INTERIOR_DESIGN },
            { question: 'question.256', key: 256, branch: BANK_FINANCE_AUDIT_MANAGEMENT },
            { question: 'question.257', key: 257, branch: COMMUNICATION_INFORMATION },
            { question: 'question.258', key: 258, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.259', key: 259, branch: TRANSPORTATION_LOGISTICS },
            { question: 'question.260', key: 260, branch: BUILDINGS_PUBLIC_WORKS },
            { question: 'question.261', key: 261, branch: SPORT_ANIMATION },
            { question: 'question.262', key: 262, branch: EDUCATION_TRAINING },
            { question: 'question.263', key: 263, branch: HUMANITARIAN, profile: C },
            { question: 'question.264', key: 264, branch: FOOD_FOOD_INDUSTRY },
            { question: 'question.265', key: 265, branch: PUBLISHING_PRINTING_BOOKS },
            { question: 'question.266', key: 266, branch: SCIENCES_MATHEMATICS_PHYSICS, profile: S },
            { question: 'question.267', key: 267, branch: BUILDINGS_PUBLIC_WORKS },
            { question: 'question.268', key: 268, branch: LAW_POLITICS },
            { question: 'question.269', key: 269, branch: ANIMALS },
            { question: 'question.270', key: 270, branch: DEFENSE_SECURITY_RESCUE },
            { question: 'question.271', key: 271, branch: HOTEL_CATERING_TOURISM },
            { question: 'question.272', key: 272, branch: TRADE_REAL_ESTATE_INSURANCE, profile: O },
            { question: 'question.273', key: 273, branch: INDUSTRY_MATERIALS },
            { question: 'question.274', key: 274, branch: CULTURE_SHOW, profile: I },
            { question: 'question.275', key: 275, branch: SCIENCES_MATHEMATICS_PHYSICS },
            { question: 'question.276', key: 276, branch: ENVIRONMENT_NATURE_CLEANING },
        ],
    },
};

export default Texts;
