import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const DescriptionForm = () => {
    const { t } = useTranslation();

    return (
        <>
            <h3 className="title title--small">{t('sociodemo.description.title')}</h3>
            <p><Trans i18nKey="sociodemo.description.text" /></p>
        </>
    );
};

export default DescriptionForm;
