import PropTypes from 'prop-types';
import { mergeRight } from 'ramda';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

const requireForm = (ComposedComponent) => {
    const Form = reduxForm({
        form: 'personalityForm',
    })(ComposedComponent);

    class FormWrapper extends Component {
        constructor(props) {
            super(props);

            this.state = {
                formId: null,
                loading: true,
                initialValues: null,
            };
        }

        async componentDidMount() {
            let form = {
                id: null,
                responses: {
                    step1: {},
                    step2: {},
                    step3: {},
                    step4: {},
                    step5: {},
                    step6: {},
                    step7: {
                        age: 1,
                    },
                    roti: {
                        satisfaction: null,
                        match: null,
                        length: null,
                        reflect: null,
                        orientation: null,
                    },
                },
            };

            const { lastForm } = this.props;

            if (lastForm) {
                form = mergeRight(form, lastForm.responses);
            }

            this.setState({
                loading: false,
                formId: form.id,
                initialValues: form.responses,
            });
        }

        render() {
            const { loading, formId, initialValues } = this.state;

            if (loading) {
                return null;
            }

            return (
                <Fragment>
                    <Form formId={formId} initialValues={initialValues} {...this.props} />
                </Fragment>
            );
        }
    }

    FormWrapper.propTypes = {
        lastForm: PropTypes.shape({}),
    };

    const mapStateToProps = ({ reconnect: { lastForm } }) => ({ lastForm });

    return connect(mapStateToProps)(FormWrapper);
};

export default requireForm;
