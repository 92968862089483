import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

const BannerJetzt = () => {
    const { t } = useTranslation();

    return (
        <a href="https://www.jetzt.de" className="banner-jetzt" target="_blank" rel="noopener noreferrer nofollow" >
            <img src="/img/logos/jetzt-white.svg" alt="" />
            {t('banner.jetzt')}
        </a>
    );
};

export default BannerJetzt;
