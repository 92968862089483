import downloadjs from 'downloadjs';
import { SubmissionError } from 'redux-form';
import Errors from '../../constants/errors.json';
import { POST } from '../utils/http';

export const exportFormResponses = (values) => async () => {
    try {
        const response = await POST('/api/admin/export', values, { jsonify: false });
        if (!response) {
            throw new SubmissionError({ _error: Errors.serverError });
        }

        const fileName = response.headers.get('Content-disposition').split('"')[1];
        const file = await response.blob();

        return downloadjs(file, fileName);
    } catch (error) {
        throw new SubmissionError({ _error: error.message });
    }
};

export default { exportFormResponses };
