import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import psicoDescriptions from '../../../../constants/psico_profile_descriptions';
import { useAppData } from '../../../hooks/useAppData';
import LanguageContext from '../../../Language/LanguageContext';
import { majorType, minorType } from '../../../propTypes/restitutionTypes';
import './style.scss';

const PageResume = ({ major, minor }) => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const { FREYA_URL, HESTER_URL, VASCO_V2_URL } = useAppData();
    const psicoProfile = psicoDescriptions[`${major}${minor}`];

    return (
        <div className="page-resume">
            <div className="page-resume__shape">
                <img
                    alt="Page soft skill shape"
                    src="/img/pdf/page-resume-shape.svg"
                />
            </div>
            <h2 className="pdf__title">{t('pdf.pageResume.title')}</h2>
            <p>
                <Trans
                    i18nKey="pdf.pageResume.text1"
                    values={{ profile: `${t(psicoProfile.major)} ${t(psicoProfile.minor)}` }}
                />
            </p>
            <p><Trans i18nKey="pdf.pageResume.text2" /></p>
            <p><Trans i18nKey="pdf.pageResume.text3" /></p>
            <div className="page-resume__next-step">
                <h2 className="pdf__title">{t('pdf.pageResume.nextStep')}</h2>
                <p><Trans i18nKey="pdf.pageResume.nextStep.text1" /></p>
                <p><Trans i18nKey="pdf.pageResume.nextStep.text2" /></p>
                <p><Trans i18nKey="pdf.pageResume.nextStep.text3" /></p>
                <ul>
                    { 'fr' === language && (
                        <li
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: t('pdf.pageResume.nextStep.text3.vasco', { VASCO_V2_URL }),
                            }}
                        />
                    )}
                    <li
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: t('pdf.pageResume.nextStep.text3.freya', { FREYA_URL }),
                        }}
                    />
                    { 'fr' === language && (
                        <li
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: t('pdf.pageResume.nextStep.text3.hester', { HESTER_URL }),
                            }}
                        />
                    )}
                </ul>
                <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: t('pdf.pageResume.nextStep.text4') }}
                />
                <p><Trans i18nKey="pdf.pageResume.nextStep.text5" /></p>
            </div>
        </div>
    );
};

PageResume.propTypes = {
    major: majorType,
    minor: minorType,
};

export default PageResume;
