/* eslint-disable no-unused-expressions, no-underscore-dangle, import/no-cycle */
import ReactGA from 'react-ga';
import webviewDetection from './webviewDetection';

const COOKIE_DURATION = 6 * 30; // This key change consent duration to 6 months

export const getCookieLanguage = () => {
    const lang = window.location.pathname.substring(1, 3);

    return ['fr', 'en', 'de'].includes(lang) ? lang : 'fr';
};

export const getCookie = (cookieName) => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
        let cookie = cookiesArray[i];
        while (' ' === cookie.charAt(0)) {
            cookie = cookie.substring(1);
        }
        if (0 === cookie.indexOf(name)) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return '';
};

export const setAxeptionSettings = (clientId, lang) => {
    window.axeptioSettings = {
        clientId,
        cookiesVersion: `jt-innovation-${lang}`,
        userCookiesDuration: COOKIE_DURATION,
    };
};

/**
* This method is used to reload the page
* when the user modifies his consent on Cookies,
* in order to apply his choices
*
* @return void
*/
export const setOnConsentChange = () => {
    undefined === window._axcb && (window._axcb = []);

    window._axcb.push((axeptio) => {
        axeptio.on('cookies:complete', (choices) => {
            const cookieStringify = getCookie('axeptio_cookies');
            const choicesStringify = JSON.stringify(choices);

            if (cookieStringify !== choicesStringify) {
                document.location.reload();
            }
        });
    });
};

export const loadReactGA = (googleAnalyticsTag) => {
    ReactGA.initialize(googleAnalyticsTag);
};

export const checkCookieConsent = () => {
    const isWebview = webviewDetection();
    if (isWebview) {
        // in webview mode, tracking analytics are automatically been accepted
        return true;
    }

    /* retreive the `axeptio_cookies` to check user consent */
    const axeptioObject = JSON.parse(getCookie('axeptio_cookies') || '{}');

    /*
  * `true` if user has accepted the consent
  * `false` or empty if the user has not validated the consent
  */
    return !!axeptioObject.google_analytics;
};

export const setCookie = (name, value, days = 365) => {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
};
