import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ReactGA from 'react-ga';
import { Trans, useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { change, Field } from 'redux-form';
import { computePiscoStats, saveResponse } from '../../../actions';
import Navigation from '../../../components/common/Navigation';
import Container from '../../../components/layout/Container';
import MainWithStep from '../../../components/layout/MainWithStep';
import Panel from '../../../components/layout/Panel';
import DescriptionExercise from '../../../components/PanelDescription/DescriptionExercise';
import Instructions from '../../../components/PanelDescription/Instructions';
import StepList from '../../../components/PanelDescription/StepList';
import ProgressionCircles from '../../../components/ProgressionCircles';
import SmileyBar from '../../../components/SmileyBar';
import requireForm from '../../../hoc/requireForm';
import requireLastFormId from '../../../hoc/requireLastFormId';
import useLocalizedHistoryPush from '../../../hooks/useLocalizedHistoryPush';
import {
    getExerciseInfos,
    getQuestion,
    isExerciseFirstQuestion,
    isExerciseLastQuestion,
    isFinalQuestion,
} from '../../../utils/exercises';
import './style.scss';

const Exercise = ({ save, lastFormId }) => {
    const push = useLocalizedHistoryPush();
    const { t } = useTranslation();
    const { step, substep } = useParams();
    const [smileyValue, setSmileyValue] = useState(null);
    const dispatch = useDispatch();
    const exerciseNumber = useMemo(() => parseInt(step, 10), [step]);
    const questionNumber = useMemo(() => parseInt(substep, 10), [substep]);

    const showInstructions = () => {
        confirmAlert({
            /* eslint-disable-next-line react/prop-types */
            customUI: ({ onClose }) => (
                <Instructions onClose={onClose}>
                    <DescriptionExercise step={step} />
                </Instructions>
            ),
        });
    };

    useEffect(() => {
        if (isExerciseFirstQuestion(exerciseNumber, questionNumber)) {
            ReactGA.event({
                category: 'Form',
                action: `start-step${questionNumber}`,
            });

            showInstructions();
        }
    }, [exerciseNumber, questionNumber]);

    const goToNextQuestion = useCallback(async (value) => {
        ReactGA.event({
            category: 'Answers',
            label: `step${exerciseNumber}-${questionNumber}`,
            value,
        });

        // Save in database
        await save(lastFormId, exerciseNumber, questionNumber, value);

        // Redirect
        if (isExerciseLastQuestion(exerciseNumber, questionNumber)) {
            if (isFinalQuestion(exerciseNumber, questionNumber)) {
                const stats = await dispatch(computePiscoStats(lastFormId));
                if (null === stats.minor || null === stats.major) {
                    push(`/step/${exerciseNumber}/rank`);

                    return;
                }
            }

            push(`/step/${exerciseNumber}/transition`);

            return;
        }

        setSmileyValue(null);
        push(`/step/${exerciseNumber}/${questionNumber + 1}`);
    }, [exerciseNumber, questionNumber, lastFormId]);

    const onBack = () => push(`/step/${exerciseNumber}/${questionNumber - 1}`);

    const exerciseInfos = useMemo(() => getExerciseInfos(exerciseNumber), [exerciseNumber]);
    const question = useMemo(() => getQuestion(exerciseNumber, questionNumber), [exerciseNumber, questionNumber]);

    return (
        <Container>
            <Panel><StepList step={exerciseNumber} /></Panel>
            <MainWithStep
                className="exercise"
                subheader={t('exercise.subheader', { stepNumber: exerciseNumber })}
                title={t(exerciseInfos.stepName)}
                subtitle={(
                    <ProgressionCircles
                        exerciseNumber={exerciseNumber}
                        questionNumber={questionNumber}
                    />
                )}
                footer={(
                    <Navigation
                        current={exerciseInfos
                            ? exerciseInfos.questions.findIndex(({ key }) => questionNumber === key)
                            : null
                        }
                        disableBack={isExerciseFirstQuestion(exerciseNumber, questionNumber)}
                        onBack={onBack}
                        total={exerciseInfos ? exerciseInfos.questions.length : null}
                        displayBar
                    />
                )}
            >
                <div className="exercise__container">
                    <div className="exercise__text">
                        <h3>{t(exerciseInfos.instruction)}</h3>
                        <p><Trans i18nKey={question.question} /></p>
                    </div>
                    <div className="smiley-bar-container">
                        <Field
                            name={`step${step}.${substep}`}
                            component={SmileyBar}
                            onChange={(value) => {
                                setSmileyValue(value);
                                setTimeout(() => goToNextQuestion(value), 250);
                            }}
                            value={smileyValue}
                        />
                    </div>
                </div>
            </MainWithStep>
        </Container>
    );
};

Exercise.propTypes = {
    lastFormId: PropTypes.string,
    save: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    save: saveResponse,
    updateField: change,
};

export default compose(
    requireForm,
    requireLastFormId,
    connect(null, mapDispatchToProps),
)(Exercise);
