import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import React, { PureComponent, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { Router, useLocation, useHistory } from 'react-router-dom';
import './App.scss';
import createStore from './createStore';
import { AppDataProvider } from './hooks/useAppData';
import useBrowserLanguage from './hooks/useBrowserLanguage';
import { MenuContext } from './hooks/useMenu';
import useUserLanguage from './hooks/useUserLanguage';
import i18n from './i18n';
import LanguageContext from './Language/LanguageContext';
import Routes from './Routes';
import { getAllLangs } from './utils/lang';
import { getUrlLanguage, getUrlWithoutLanguage } from './hooks/useUrlParams';

const LanguageProvider = ({ children }) => {
    const { pathname } = useLocation();
    const { push } = useHistory();
    const userLanguage = useUserLanguage();
    const browserLanguage = useBrowserLanguage();
    const urlLanguage = getUrlLanguage(pathname);

    let initLanguage = browserLanguage;
    if (urlLanguage) {
        initLanguage = urlLanguage;
    }
    if (userLanguage) {
        initLanguage = userLanguage;
    }
    const [language, setLanguage] = useState(initLanguage);
    const allowedLanguages = getAllLangs().map(({ keyLang }) => keyLang);

    useEffect(() => {
        if (userLanguage) {
            setLanguage(userLanguage);
            if (allowedLanguages.includes(userLanguage) && '/missing-language' === pathname) {
                push(`/${userLanguage}/account`);
            } else if (pathname !== '/') {
                push(`/${userLanguage}/${getUrlWithoutLanguage(language, pathname)}`);
            } else {
                push(`/${userLanguage}`);
            }
        }
    }, [userLanguage]);

    useEffect(() => {
        if (allowedLanguages.includes(language)) {
            i18n.changeLanguage(language);
        }
    }, [language]);

    useEffect(() => {
        if (urlLanguage && urlLanguage !== language) {
            setLanguage(urlLanguage);
        }
    }, [urlLanguage]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage, allowedLanguages }}>
            {children}
        </LanguageContext.Provider>
    );
};

LanguageProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const MenuProvider = ({ children }) => {
    const [menuIsOpen, openMenu] = useState(false);

    return (
        <MenuContext.Provider value={{ menuIsOpen, openMenu }}>
            {children}
        </MenuContext.Provider>
    );
};

MenuProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

class App extends PureComponent {
    constructor(props) {
        super(props);

        this.history = createBrowserHistory();
        this.store = createStore({}, { history: this.history });

        // Disable fast-tap zoom on mobile
        let lastTouchEnd = 0;
        document.addEventListener('touchend', (event) => {
            const now = (new Date()).getTime();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault();
            }

            lastTouchEnd = now;
        }, false);
    }

    componentDidMount() {
        this.history.listen((location) => ReactGA.pageview(location.pathname));
    }

    render() {
        const { appData } = this.props;

        return (
            <AppDataProvider appData={appData}>
                <Provider store={this.store}>
                    <Router history={this.history}>
                        <LanguageProvider>
                            <MenuProvider>
                                <Routes />
                            </MenuProvider>
                        </LanguageProvider>
                    </Router>
                </Provider>
            </AppDataProvider>
        );
    }
}

export default App;
