import PropTypes from 'prop-types';
import React from 'react';
import { statsType } from '../../propTypes/restitutionTypes';
import BackPage from './BackPage';
import FrontPage from './FrontPage';
import PageIntro1 from './PageIntro1';
import PageIntro2 from './PageIntro2';
import PageLayout from './PageLayout';
import PageProfessionalGroups from './PageProfessionalGroups';
import PageProfessionalInterests from './PageProfessionalInterests';
import PageProfiles from './PageProfiles';
import PageResult from './PageResult';
import PageResume from './PageResume';
import './style.scss';

const Restitution = ({ createdAt, stats, user }) => (
    <div className="pdf-layout">
        <FrontPage date={createdAt} user={user} />
        <PageLayout date={createdAt} page={2} user={user}>
            <PageIntro1 />
        </PageLayout>
        <PageLayout date={createdAt} page={3} user={user}>
            <PageIntro2 />
        </PageLayout>
        <PageLayout date={createdAt} page={4} user={user} withoutPadding>
            <PageResult {...stats} user={user} />
        </PageLayout>
        <PageProfessionalInterests
            {...stats}
            date={createdAt}
            firstPageIndex={5}
            user={user}
        />
        <PageLayout date={createdAt} page={8} user={user}>
            <PageResume {...stats} />
        </PageLayout>
        <PageLayout date={createdAt} page={9} user={user} withoutPadding>
            <PageProfiles />
        </PageLayout>
        <PageProfessionalGroups
            date={createdAt}
            firstPageIndex={10}
            user={user}
        />
        <PageLayout date={createdAt} page={14} user={user} backpage withoutPadding>
            <BackPage />
        </PageLayout>
    </div>
);

Restitution.propTypes = {
    createdAt: PropTypes.string.isRequired,
    stats: statsType,
    user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
    }).isRequired,
};

export default Restitution;
