import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import psicoProfileTypes from '../../propTypes/psicoProfileTypes';
import radarDataTypes from '../../propTypes/radarDataTypes';
import RadarChart from '../RadarChart';
import './style.scss';

const RadarBlock = ({ psicoProfile, data, gender, language }) => {
    // needed to update radar chart size
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, []);
    const { t } = useTranslation();

    return (
        <div className="result-block radar-chart-block">
            <span className="radar-description">{t(psicoProfile[`spider_text_${gender}`])}</span>
            <RadarChart data={data} psicoProfile={psicoProfile} />
            {'de' === language && (
                <span dangerouslySetInnerHTML={{ __html: t('results.radar.jobteaser-link') }} />
            )}
        </div>
    );
};

RadarBlock.propTypes = {
    psicoProfile: psicoProfileTypes,
    data: radarDataTypes,
    gender: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
};

export default RadarBlock;
