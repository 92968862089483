import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './style.scss';

const PageIntro1 = () => {
    const { t } = useTranslation();

    return (
        <>
            <h2 className="pdf__title">{t('pdf.pageIntro1.title')}</h2>
            <div className="pdf__text--justify">
                <p><Trans i18nKey="pdf.pageIntro1.text1" /></p>
                <p><Trans i18nKey="pdf.pageIntro1.text2" /></p>
                <p><Trans i18nKey="pdf.pageIntro1.text3" /></p>
                <p><Trans i18nKey="pdf.pageIntro1.text4" /></p>
                <p><Trans i18nKey="pdf.pageIntro1.text5" /></p>
                <p><Trans i18nKey="pdf.pageIntro1.text6" /></p>
                <p><Trans i18nKey="pdf.pageIntro1.text7" /></p>
                <p><Trans i18nKey="pdf.pageIntro1.text8" /></p>
            </div>
            <div className="pdf-intro-shape">
                <img
                    alt="Intro page shape"
                    src="/img/pdf/pdf-intro-shape.svg"
                />
            </div>
        </>
    );
};

export default PageIntro1;
