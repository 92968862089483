import React from 'react';
import { useMenu } from '../../../hooks/useMenu';

const CloseButton = () => {
    const { openMenu } = useMenu();

    return (
        <button onClick={() => openMenu(false)} type="button">
            <img
                alt="close"
                src="/img/icons/white-cross.svg"
            />
        </button>
    );
};

export default CloseButton;
