import PropTypes from 'prop-types';

export const branchesType = PropTypes.shape({}).isRequired;

export const majorType = PropTypes.string.isRequired;

export const minorType = PropTypes.string.isRequired;

export const profilesType = PropTypes.shape({
    C: PropTypes.number.isRequired,
    I: PropTypes.number.isRequired,
    O: PropTypes.number.isRequired,
    P: PropTypes.number.isRequired,
    S: PropTypes.number.isRequired,
}).isRequired;

export const statsType = PropTypes.shape({
    branches: branchesType,
    major: majorType,
    minor: minorType,
    profiles: profilesType,
}).isRequired;
