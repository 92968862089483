import { useDispatch } from 'react-redux';
import { cleanUser, deleteLastForm } from '../actions';
import { logout } from '../jt-client/actions';
import useLocalizedHistoryPush from './useLocalizedHistoryPush';

const useLogout = () => {
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();

    return () => {
        push('/');
        dispatch(logout());
        dispatch(deleteLastForm());
        dispatch(cleanUser());
    };
};

export default useLogout;
