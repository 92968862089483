import { isEmpty, path } from 'ramda';
import { getExerciseFirstQuestionNumber, isExerciseFirstQuestion, isExerciseLastQuestion } from './exercises';

export function previousStepURL({ form }) {
    if (!form
        || !form.responses
        // all steps empty
        || Object.keys(form.responses).reduce((acc, step) => acc && isEmpty(form.responses[step]), true)
    ) {
        return '/step/1';
    }

    if (path(['responses', 'step7'], form)) {
        return '/step/7';
    }

    const profilesToDecide = path(['stats', 'profilesToDecide'], form);
    // we answered to all questions but we need to answer more questions to determine the archetype
    if (profilesToDecide && profilesToDecide.length > 0) {
        return '/step/6/rank';
    }

    // we did not answer all questions, we find the next one
    const { exercise, question } = Object
        .keys(form.responses)
        .filter((step) => !isEmpty(form.responses[step]))
        .reduce(({ exercise: accExercise, question: accQuestion }, stepKey) => {
            // step1 => 1, step2 => 2
            const stepNumber = parseInt(stepKey.replace('step', ''), 10);
            /*
                we've answers for the next exercise
                or we're here because we programmatically went to the next exercise first question
                (still need to check the last question answered for the exercise)
             */
            if (stepNumber > accExercise || isExerciseFirstQuestion(stepNumber, accQuestion)) {
                // get the last question answered of the exercise
                const questionNumber = Object
                    .keys(form.responses[stepKey])
                    .reduce((questionAcc, questionKey) => {
                        const currentQuestionNumber = parseInt(questionKey, 10);
                        if (currentQuestionNumber > questionAcc) {
                            return currentQuestionNumber;
                        }

                        return questionAcc;
                    }, 0);

                // last question => go to next exercise first question
                if (isExerciseLastQuestion(stepNumber, questionNumber)) {
                    if (6 === stepNumber) {
                        return {
                            exercise: 6,
                            question: 'transition',
                        };
                    }
                    const nextExerciseNumber = stepNumber + 1;

                    return {
                        exercise: nextExerciseNumber,
                        question: getExerciseFirstQuestionNumber(nextExerciseNumber),
                    };
                }

                // not the last question => we stay on the same exercise but go to the next question
                return {
                    exercise: stepNumber,
                    question: questionNumber + 1,
                };
            }

            // we finally found the next exercise and the next question
            return {
                exercise: accExercise,
                question: accQuestion,
            };
        }, { exercise: 1, question: 1 });

    return `/step/${exercise}/${question}`;
}

export default previousStepURL;
