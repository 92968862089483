import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './style.scss';

const PageIntro2 = () => {
    const { t } = useTranslation();

    const part1 = [1, 2, 3];
    const part2 = [1, 2];

    return (
        <div className="pdf-intro2">
            <div className="pdf-intro2__shape">
                <img
                    alt="Intro page shape"
                    src="/img/pdf/pdf-intro2-shape.svg"
                />
            </div>
            <h2 className="pdf__title">{t('pdf.pageIntro2.title')}</h2>
            <div className="pdf__text--justify">
                <p><Trans i18nKey="pdf.pageIntro2.intro" /></p>
            </div>
            <div className="pdf-intro2__title-container">
                <img
                    alt="First bullet point"
                    src="/img/pdf/bullet-point-1.svg"
                />
                <h3 className="pdf__title">{t('pdf.pageIntro2.part1.title')}</h3>
            </div>
            <ul>
                {part1.map((index) => (
                    <li key={index}><Trans i18nKey={`pdf.pageIntro2.part1.bullet${index}`} /></li>
                ))}
            </ul>
            <div className="pdf-intro2__title-container">
                <img
                    alt="Second bullet point"
                    src="/img/pdf/bullet-point-2.svg"
                />
                <h3 className="pdf__title">{t('pdf.pageIntro2.part2.title')}</h3>
            </div>
            <ul>
                {part2.map((index) => (
                    <li key={index}><Trans i18nKey={`pdf.pageIntro2.part2.bullet${index}`} /></li>
                ))}
            </ul>
        </div>
    );
};

export default PageIntro2;
