import { SubmissionError } from 'redux-form';
import { GET, PATCH, DELETE } from '../utils/http';

export const CLEAN_USER = 'CLEAN_USER';
export const SET_USER = 'SET_USER';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';

export const getUser = () => async (dispatch) => {
    try {
        const user = await GET('/api/user');
        dispatch({ type: SET_USER, user });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const updateUserLanguage = (values) => async (dispatch) => {
    try {
        await PATCH('/api/user/language', values, { jsonify: false });
        dispatch({ type: SET_USER_LANGUAGE, language: values.language });
    } catch (e) {
        throw new SubmissionError({ _error: 'error.server.unknown' });
    }
};

export const deleteAccount = () => async () => {
    try {
        const response = await DELETE('/api/user/');

        if (response.success) {
            return true;
        }

        return null;
    } catch (e) {
        console.error(e);

        return null;
    }
};

export const cleanUser = () => ({
    type: CLEAN_USER,
});
