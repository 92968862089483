import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

const Footer = ({ createdAt, customText, user, pageNb }) => {
    const { t } = useTranslation();

    return (
        <div className="pdf__footer">
            {customText ? (
                <span>{customText}</span>
            ) : (
                <>
                    <span>{` ${user.surname} ${user.name} - ${createdAt} - ${t('pdf.footer.text')} `}</span>
                    <span>{pageNb}</span>
                </>
            )}
        </div>
    );
};

Footer.propTypes = {
    createdAt: PropTypes.string.isRequired,
    customText: PropTypes.string,
    pageNb: PropTypes.number.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
    }).isRequired,
};

export default Footer;
