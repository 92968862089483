import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { LIKERT_MAX_VALUE } from '../../../constants';
import getBranchesRanking from '../../utils/branches';
import './index.scss';

const BRANCHES_RANKING_COUNT = 10;

const MatchingBranchesRanking = ({
    branches,
    className = '',
    compact = false,
    displayValue = false,
    legend = true,
    noTitle = false,
    showAll = false,
}) => {
    const { t } = useTranslation();
    const maxBranches = showAll ? 0 : BRANCHES_RANKING_COUNT;
    const ranking = getBranchesRanking(branches, maxBranches);

    return (
        <div
            className={classnames('motivation-engines', 'result-block', className)}
            style={{ padding: compact ? '0px' : '36px' }}
        >
            { !noTitle && <h1>{t('results.matching_jobs_ranking.title')}</h1> }
            {Object.keys(ranking).map((branchKey) => {
                const percent = ranking[branchKey] / LIKERT_MAX_VALUE * 100;

                return (
                    <div className={classnames({ 'branch-bloc': compact })} key={branchKey}>
                        <div className="branch-title">
                            {t(`common.branch.${branchKey}`)}
                            {displayValue && (
                                <span className="branch-value">{` - ${percent.toFixed(2)}%`}</span>
                            )}
                        </div>
                        <div className="horizontal-bar">
                            <div
                                className="fill-bar"
                                style={{ width: `${percent}%` }}
                            />
                        </div>
                    </div>
                );
            })}
            { legend && (
                compact ? (
                    <div className="branch-bloc">
                        <div className="branch-title" />
                        <div className="legend">
                            <div>
                                <Trans i18nKey="results.matching_jobs_ranking.legend.upper_scale" />
                            </div>
                            <div className="legend__lower-scale-text">
                                <Trans i18nKey="results.matching_jobs_ranking.legend.lower_scale" />
                            </div>
                        </div>
                    </div>
                )
                    : (
                        <div className="legend">
                            <div style={{ whiteSpace: showAll ? 'nowrap' : 'normal' }}>
                                <Trans i18nKey="results.matching_jobs_ranking.legend.lower_scale" />
                            </div>
                            <div
                                className="legend__lower-scale-text"
                                style={{ whiteSpace: showAll ? 'nowrap' : 'normal' }}
                            >
                                <Trans i18nKey="results.matching_jobs_ranking.legend.upper_scale" />
                            </div>
                        </div>
                    )
            )}
        </div>
    );
};

MatchingBranchesRanking.propTypes = {
    branches: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
    compact: PropTypes.bool,
    displayValue: PropTypes.bool,
    legend: PropTypes.bool,
    noTitle: PropTypes.bool,
    showAll: PropTypes.bool,
};

export default MatchingBranchesRanking;
