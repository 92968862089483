import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

const LegalPanelContent = () => {
    const { t } = useTranslation();

    return (
        <div className="legal-panel-content">
            <h3 className="title title--small">{t('common.legalPanel.title')}</h3>
            <p>{t('common.legalPanel.description')}</p>
            <img alt="Marco illustration" src="/img/panel-illustration.svg" />
        </div>
    );
};

export default LegalPanelContent;
