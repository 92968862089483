import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

const CheckboxField = ({ children, className = '', input, meta: { touched, error, warning } }) => {
    const { t } = useTranslation();

    return (
        <div
            className={`
                checkbox
                ${touched && error ? 'checkbox--error' : ''}
                ${touched && warning ? 'checkbox--warning' : ''}
                ${className}
            `}
        >
            <input checked={input.value} id={input.name} type="checkbox" {...input} />
            <div>
                {/* eslint-disable-next-line */}
                <label htmlFor={input.name}>{children}</label>
                {touched
                    && ((error && <p>{t(error)}</p>)
                    || (warning && <p>{t(warning)}</p>))}
            </div>
        </div>
    );
};

CheckboxField.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    input: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
        ]),
    }),
    meta: PropTypes.shape({
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
        warning: PropTypes.string,
    }),
};

export default CheckboxField;
