import React from 'react';
import useBrowserLanguage from '../hooks/useBrowserLanguage';

const withBrowserLanguage = (ComposedComponent) => (props) => {
    const browserLanguage = useBrowserLanguage();

    return (
        <ComposedComponent browserLanguage={browserLanguage} {...props} />
    );
};

export default withBrowserLanguage;
