import Errors from '../../constants/errors.json';
import i18n from '../i18n';

export const required = (value) => (!value ? i18n.t('error.field.required') : null);

export const requireSurname = (value) => (!value ? Errors.emptyField.surname : null);
export const requireName = (value) => (!value ? Errors.emptyField.name : null);
export const requireEmail = (value) => (!value ? Errors.emptyField.email : null);
export const requirePassword = (value) => (!value ? Errors.emptyField.password : null);
export const requireLanguage = (value) => (!value ? Errors.emptyField.language : null);

export const emailFormat = (value) => (
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? Errors.invalidEmail : null
);

export const passwordsMatch = (value, allValues) => (value !== allValues.password
    ? i18n.t('error.password.mismatch')
    : null);
