import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLastForm } from '../actions/reconnect';
import { GET } from '../utils/http';
import { path } from 'ramda';

const requireLastFormId = (ComposedComponent) => {
    class FormWrapper extends Component {
        async componentDidMount() {
            const { lastFormId, setLastFormAction } = this.props;
            if (!lastFormId) {
                const { form } = await GET('/api/personality-form/last_form');
                setLastFormAction(form);
            }
        }

        render() {
            const { lastFormId } = this.props;

            return (
                <ComposedComponent lastFormId={lastFormId} {...this.props} />
            );
        }
    }

    FormWrapper.propTypes = {
        lastFormId: PropTypes.string,
        setLastFormAction: PropTypes.func.isRequired,
    };

    const mapStateToProps = ({ reconnect }) => ({ lastFormId: path(['lastForm', 'id'], reconnect) });

    const mapDispatchToProps = { setLastFormAction: setLastForm };

    return connect(mapStateToProps, mapDispatchToProps)(FormWrapper);
};

export default requireLastFormId;
