import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import webviewDetection from '../utils/webviewDetection';

export const AppDataContext = createContext({});

const defaultCtx = {
    isWebview: false,
};

export const useAppData = () => useContext(AppDataContext);

export const AppDataProvider = ({ children, appData }) => {
    const [state, setState] = useState({
        ...defaultCtx,
        ...appData,
    });

    useEffect(() => {
        function getEnvironment() {
            setState({
                ...state,
                isWebview: webviewDetection(),
            });
        }

        getEnvironment();
    }, []);

    return <AppDataContext.Provider value={state}>{children}</AppDataContext.Provider>;
};

AppDataProvider.propTypes = {
    appData: PropTypes.shape({
        AUTH_URL: PropTypes.string.isRequired,
        COOKIE_CLIENT_ID: PropTypes.string.isRequired,
        FREYA_URL: PropTypes.string.isRequired,
        HESTER_URL: PropTypes.string.isRequired,
        INNOVATION_SITE_URL: PropTypes.string.isRequired,
        MARCO_V3_URL: PropTypes.string.isRequired,
        MARCO_V4_URL: PropTypes.string.isRequired,
        VASCO_V2_URL: PropTypes.string.isRequired,
        googleAnalyticsTag: PropTypes.string.isRequired,
    }).isRequired,
    children: PropTypes.node.isRequired,
};
