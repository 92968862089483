import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppData } from '../../../hooks/useAppData';
import LanguageContext from '../../../Language/LanguageContext';
import Button from '../../common/Button';
import HomeContentCard from './HomeContentCard';
import './style.scss';

const CardScience = () => {
    const { t } = useTranslation();

    return (
        <HomeContentCard
            image="/img/home/science.svg"
            text={t('home.card.science.content')}
            title={t('home.card.science.title')}
        />
    );
};

const CardKnowledge = () => {
    const { t } = useTranslation();

    return (
        <HomeContentCard
            image="/img/home/head.svg"
            text={t('home.card.knowledge.content')}
            title={t('home.card.knowledge.title')}
        />
    );
};

const CardSerenity = () => {
    const { t } = useTranslation();

    return (
        <HomeContentCard
            image="/img/home/serenity.svg"
            text={t('home.card.serenity.content')}
            title={t('home.card.serenity.title')}
        />
    );
};

const CardTools = () => {
    const { t } = useTranslation();

    return (
        <HomeContentCard
            image="/img/home/documents.svg"
            text={t('home.card.tools.content')}
            title={t('home.card.tools.title')}
        />
    );
};

const Content = ({ onClick }) => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const { INNOVATION_SITE_URL, FREYA_URL, HESTER_URL, VASCO_V2_URL } = useAppData();

    return (
        <main role="main">
            <div className="home-content home-content__container">
                <div className="home-content__top">
                    <div className="home-cards">
                        <div className="home-cards--left">
                            <CardScience />
                            <CardKnowledge />
                        </div>
                        <div className="home-cards--right">
                            <CardSerenity />
                            <CardTools />
                        </div>
                    </div>
                    <div className="home-content__text--top">
                        <h2
                            className="home-content__title"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: t('home.content.cards_text.title') }}
                        />
                        <p className="home-content__text">{t('home.content.cards_text.text1')}</p>
                        <p className="home-content__text">{t('home.content.cards_text.text2')}</p>
                        <Button className="button--black" onClick={onClick} text={t('home.start_button')} />
                    </div>
                    <div className="home-block-cards">
                        <div className="home-block-cards__wrapper">
                            <CardScience />
                            <CardKnowledge />
                        </div>
                        <div className="home-block-cards__wrapper">
                            <CardSerenity />
                            <CardTools />
                        </div>
                    </div>
                </div>
                <div className="home-expectation">
                    <div className="home-expectation__text">
                        <div>
                            <h2 className="home-content__title">
                                {t('home.content.expectation.title')}
                            </h2>
                            <p><Trans i18nKey="home.content.expectation.content" /></p>
                        </div>
                    </div>
                    <div className="home-expectation__illustration__wrapper">
                        <img
                            alt="results-example"
                            className="home-expectation__illustration"
                            src="/img/home/result-examples.gif"
                        />
                    </div>
                </div>
                <div className="home-your-way">
                    <img alt="your-way" src="/img/home/thinking.svg" />
                    <div className="home-your-way__text">
                        <h2 className="home-content__title home-your-way__title">{t('home.content.your_way.title')}</h2>
                        <p>{t('home.content.your_way.text')}</p>
                        <ul className="home-your-way__other-products">
                            { 'fr' === language && (
                                <li>
                                    <Trans i18nKey="home.content.your_way.hester" />
                                    <a href={HESTER_URL}>{t('common.hester')}</a>
                                </li>
                            )}
                            <li>
                                <Trans i18nKey="home.content.your_way.freya" />
                                <a href={FREYA_URL}>{t('common.freya')}</a>
                            </li>
                            { 'fr' === language && (
                                <li>
                                    <Trans i18nKey="home.content.your_way.vasco" />
                                    <a href={VASCO_V2_URL}>{t('common.vasco')}</a>
                                </li>
                            )}
                        </ul>
                        <Button
                            className="home-your-way__button button--black"
                            onClick={() => {
                                window.location.href = INNOVATION_SITE_URL;
                            }}
                            text={t('home.content.your_way.button')}
                        />
                    </div>
                </div>
            </div>
            <div className="home-content__end">
                <p className="home-content__end-title">{t('home.footer.start')}</p>
                <Button className="button--black" onClick={onClick} text={t('home.start_button')} />
            </div>
            <div className="home-content">
                <div className="home-content__container home-content__bottom">
                    <img alt="" className="home-content__bottom-image" src="/img/home/investigator.svg" />
                    <div className="home-content__bottom-text">
                        <h2 className="home-content__title">
                            {t('home.footer.mission.title')}
                        </h2>
                        <p>{t('home.footer.mission.content')}</p>
                        <div className="home-content__bottom-logos">
                            <a
                                className="home-content__logo"
                                href="https://www.parisdescartes.fr/"
                            >
                                <img alt="Logo Universite Paris" src="/img/logos/paris-descartes.svg" />
                            </a>
                            <a
                                className="home-content__logo"
                                href="https://www.jobteaser.com"
                            >
                                <img alt="Logo Job Teaser" src="/img/logos/jobteaser-black.svg" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

Content.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default Content;
