import PropTypes from 'prop-types';
import React from 'react';
import Texts from '../../../constants/texts';
import Step from './Step';

const StepList = ({ currentStep, onRestart }) => {
    const getStateClass = (step) => {
        if (currentStep + 1 === step) {
            return 'current';
        }

        return currentStep + 1 > step ? 'done' : 'waiting';
    };

    return (
        <ul>
            {Object.keys(Texts).map((exerciseKey, index) => {
                const { stepName } = Texts[exerciseKey];
                const exerciseNumber = index + 1;

                return (
                    <Step
                        key={exerciseNumber}
                        number={exerciseNumber}
                        description={stepName}
                        state={getStateClass(exerciseNumber)}
                        onRestart={onRestart}
                    />
                );
            })}
        </ul>
    );
};

StepList.propTypes = {
    currentStep: PropTypes.number.isRequired,
    onRestart: PropTypes.func.isRequired,
};

export default StepList;
