import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

import { C, I, O, P, S } from '../../../../constants/psico_profiles';
import { FACEBOOK, LINKEDIN } from '../../../utils/shareOnSocialNetwork';
import { GET } from '../../../utils/http';
import { getPdfName } from '../../../../api/utils/getPdfName';
import ArchetypeBlock from '../../../components/ArchetypeBlock';
import Button from '../../../components/common/Button';
import Container from '../../../components/layout/Container';
import LanguageContext from '../../../Language/LanguageContext';
import MatchingBranchesRanking from '../../../components/MatchingBranchesRanking';
import Modal from '../../../components/common/Modal';
import Panel from '../../../components/layout/Panel';
import PdfModal from '../../../components/PdfModal';
import ProfileCard from '../../../components/ResultProfileCard';
import psicoDescriptions from '../../../../constants/psico_profile_descriptions';
import RadarBlock from '../../../components/RadarBlock';
import ResultsDescription from '../../../components/PanelDescription/ResultsDescription';
import ShareButton from '../../../components/ShareButton';
import useLocalizedHistoryPush from '../../../hooks/useLocalizedHistoryPush';
import './style.scss';

const getGender = (originalGender) => ('male' === originalGender ? originalGender : 'female');

const DOWNLOAD_PDF_ELEMENT_ID = 'dlFile';

const Results = ({ match: { params: { formId } }, userName }) => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const [state, setState] = useState({
        createdAt: null,
        downloading: false,
        sendingByEmail: false,
        pdfUrl: null,
        user: null,
        stats: null,
    });
    const pdfName = useMemo(() => getPdfName(
        t('results.pdf.prefix', { lng: language }),
        state.createdAt, userName,
    ), [state, userName, language]);
    const push = useLocalizedHistoryPush();

    useEffect(() => {
        ReactGA.event({
            category: 'Form',
            action: 'end-form',
        });
        ReactGA.event({
            category: 'Form',
            action: 'see-results',
        });
        const urlParams = new URLSearchParams(window.location.search);
        const redirectHome = urlParams.get('redirectHome');
        if (redirectHome) {
            push('/');
        }
    }, []);

    useEffect(() => {
        const getRestitutionData = async () => {
            if (formId) {
                const { user, createdAt, stats } = await GET(
                    `/api/personality-form/restitution-data/${formId}`,
                );
                setState({ ...state, createdAt, user, stats });
            }
        };

        getRestitutionData();
    }, [formId]);

    const onDownloadPdfClick = () => {
        setOpenPdfModal(true);
    };

    const onReportShareButtonClick = () => {
        confirmAlert({
            // eslint-disable-next-line react/prop-types
            customUI: ({ onClose }) => (
                <Modal closeModal={onClose} title={t('common.share_your_results')} fullscreen>
                    <div className="share-modal__buttons-container">
                        <ShareButton
                            afterOnClick={onClose}
                            shareUrl={window.location.href}
                            buttonContent="social_network.share.linkedin"
                            socialNetwork={LINKEDIN}
                        />
                        <ShareButton
                            afterOnClick={onClose}
                            shareUrl={window.location.href}
                            buttonContent="social_network.share.facebook"
                            socialNetwork={FACEBOOK}
                        />
                    </div>
                </Modal>
            ),
        });
    };

    const {
        user,
        stats,
    } = state;
    if (!stats) {
        return null;
    }
    const { major, minor, branches } = stats;
    const psicoProfile = psicoDescriptions[`${major}${minor}`];

    const data = [
        { label: t('common.profile.P'), value: stats.profiles[P] },
        { label: t('common.profile.S'), value: stats.profiles[S] },
        { label: t('common.profile.I'), value: stats.profiles[I] },
        { label: t('common.profile.C'), value: stats.profiles[C] },
        { label: t('common.profile.O'), value: stats.profiles[O] },
    ];

    const gender = getGender(user.gender);

    return (
        <Container>
            <Panel>
                <ResultsDescription
                    onReportDownloadButtonClick={onDownloadPdfClick}
                    onReportShareButtonClick={onReportShareButtonClick}
                />
            </Panel>
            {openPdfModal && (
                <PdfModal
                    formId={formId}
                    language={language}
                    onClose={() => setOpenPdfModal(false)}
                />
            )}
            <a
                id={DOWNLOAD_PDF_ELEMENT_ID}
                href={state.pdfUrl}
                download={pdfName}
                style={{ display: 'none' }}
            />
            <div className="results__wrapper">
                <img alt="" className="results__top" src="/img/results/top.svg" />
                <div className="results__container">
                    <ProfileCard psicoProfile={psicoProfile} />
                    <div />
                    <RadarBlock
                        psicoProfile={psicoProfile}
                        data={data}
                        gender={gender}
                        language={language}
                    />
                    <ArchetypeBlock
                        userGender={gender}
                        psicoProfile={psicoProfile}
                    />
                    <MatchingBranchesRanking branches={branches} />
                    <div className="results__share">
                        {t('results.share.download_button_description')}
                        <Button
                            className="button--black results__share__button"
                            img="/img/icons/download-black.svg"
                            onClick={onDownloadPdfClick}
                            text={t('common.download_report')}
                        />
                        {t('results.share.share_button_description')}
                        <Button
                            className="button--black results__share__button"
                            img="/img/icons/share.svg"
                            onClick={onReportShareButtonClick}
                            text={t('common.share_your_results')}
                        />
                    </div>
                </div>
            </div>
        </Container>
    );
};

Results.propTypes = {
    userName: PropTypes.string,
    match: PropTypes.shape({
        params: PropTypes.shape({
            formId: PropTypes.string.isRequired,
        }),
    }),
};

const mapStateToProps = ({
    auth: { id, name },
}) => ({
    loggedUserId: id,
    userName: name,
});

export default connect(mapStateToProps)(Results);
