import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { LIKERT_MAX_VALUE } from '../../../constants';
import Smiley from './Smiley';
import './style.scss';

const smileyValues = Array.from(Array(LIKERT_MAX_VALUE + 1).keys());

const SmileyBar = ({ input: { value, onChange }, hateScale, loveScale, neutralScale, className }) => {
    const { t } = useTranslation();

    return (
        <div className={classnames('smiley-bar', className)}>
            <div className="smileys-container">
                {
                    smileyValues.map((i) => (
                        <Smiley
                            key={i}
                            value={i}
                            isSelected={value === i}
                            onSelect={() => onChange(i)}
                        />
                    ))
                }
            </div>
            {hateScale || loveScale || neutralScale
                ? (
                    <div className="labels-container">
                        {hateScale && (
                            <div className="column-text-left">
                                {t(hateScale)}
                            </div>
                        )}
                        {neutralScale && (
                            <div className="column-text">
                                {t(neutralScale)}
                            </div>
                        )}
                        {loveScale && (
                            <div className="column-text-right">
                                {t(loveScale)}
                            </div>
                        )}
                    </div>
                )
                : null}
        </div>
    );
};

SmileyBar.propTypes = {
    className: PropTypes.string,
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }).isRequired,
    hateScale: PropTypes.string,
    neutralScale: PropTypes.string,
    loveScale: PropTypes.string,
};

SmileyBar.defaultProps = {
    hateScale: 'exercise.notation_scale.hate',
    neutralScale: 'exercise.notation_scale.neutral',
    loveScale: 'exercise.notation_scale.love',
};

export default SmileyBar;
