import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../common/Button';
import Main from '../Main';
import './style.scss';

const MainWithBackButton = ({ children, title }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const onGoBack = () => history.goBack();

    return (
        <div className="main-container">
            <div className="main-back">
                <Button
                    className="button--transparent main-back__button"
                    img="/img/icons/back-arrow.svg"
                    onClick={onGoBack}
                    text={t('common.legalPage.back')}
                />
                <Main>
                    <h1 className="title main-back__title">{title}</h1>
                    <div className="main-back__content">
                        {children}
                    </div>
                </Main>
            </div>
        </div>
    );
};

MainWithBackButton.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
};

export default MainWithBackButton;
