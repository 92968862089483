import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import psicoProfileTypes from '../../propTypes/psicoProfileTypes';
import './index.scss';

const ResultsProfile = ({ psicoProfile: { major, minor, text } }) => {
    const { t } = useTranslation();

    return (
        <div className="result-profile-card result-block">
            <h1 className="result-profile-card__title">{t('common.you_are')}</h1>
            <h1 className="result-profile-card__title uppercase">{`${t(major)} ${t('common.and')} ${t(minor)}`}</h1>
            <p><Trans i18nKey={text} /></p>
        </div>
    );
};

ResultsProfile.propTypes = {
    psicoProfile: psicoProfileTypes,
};

export default ResultsProfile;
