import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const Branches = ({ branches }) => {
    const { t } = useTranslation();

    return (
        <div className="branches">
            {
                branches.map((b) => (
                    <div key={b}>
                        <p>{t(`common.branch.${b}`)}</p>
                        {t(`common.branch.${b}.description`)}
                    </div>
                ))
            }
        </div>
    );
};

Branches.propTypes = {
    branches: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Branches;
