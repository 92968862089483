import { useState, useEffect } from 'react';
import variables from '../styles/_variables.scss';

function useWindowSize() {
    const isClient = 'object' === typeof window;

    function getSize() {
        let size;
        if (isClient) {
            const { innerWidth } = window;
            if (innerWidth <= parseInt(variables.mobile, 10)) {
                size = 'xs';
            } else if (innerWidth <= parseInt(variables.tablet, 10)) {
                size = 'sm';
            } else if (innerWidth <= parseInt(variables.desktop, 10)) {
                size = 'md';
            } else {
                size = 'lg';
            }
        }

        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
            size,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

export default useWindowSize;
