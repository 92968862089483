import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './style.scss';

const Backdrop = ({ children, className = '', zIndex }) => (
    <div className={classnames('backdrop', className)} style={{ zIndex }}>
        {children}
    </div>
);

Backdrop.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    zIndex: PropTypes.number,
};

export default Backdrop;
