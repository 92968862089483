import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './style.scss';

const DescriptionAccount = () => {
    const { t } = useTranslation();
    const { surname } = useSelector(({ user }) => user);

    return (
        <div className="descriptionAccount">
            <h3 className="title title--small">{t('account.welcome', { username: surname })}</h3>
            <p>{t('account.description')}</p>
            <img alt="freya" src="/img/panel-illustration.svg" />
        </div>
    );
};

export default DescriptionAccount;
