import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import questionsToDecide from '../../../../constants/step-6-rank';
import { chooseArchetype, updateProfilesToDecide } from '../../../actions';
import ActivityDisplayer from '../../../components/ActivityDisplayer';
import Button from '../../../components/common/Button';
import Container from '../../../components/layout/Container';
import MainWithStep from '../../../components/layout/MainWithStep';
import Panel from '../../../components/layout/Panel';
import StepList from '../../../components/PanelDescription/StepList';
import requireLastFormId from '../../../hoc/requireLastFormId';
import useLocalizedHistoryPush from '../../../hooks/useLocalizedHistoryPush';
import './style.scss';

const Step6Rank = ({ lastFormId }) => {
    useEffect(() => {
        ReactGA.event({
            category: 'Form',
            action: 'start-step6Rank',
        });
    }, []);
    // we know that this component is always displayed after step6, so we arbitrarily set 6 as exercise number
    const exerciseNumber = 6;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const push = useLocalizedHistoryPush();
    const profilesToDecide = useSelector(
        (state) => path(['reconnect', 'lastForm', 'stats', 'profilesToDecide'], state),
    );
    const [selectedItems, setSelectedItems] = useState({});
    const selectedProfiles = useMemo(() => Object
        .keys(selectedItems)
        .filter((profile) => selectedItems[profile]),
    [selectedItems]);
    const canGuessMajorMinor = useMemo(() => profilesToDecide && 2 === profilesToDecide.length, [profilesToDecide]);

    const canSelectMore = useMemo(() => {
        if (!profilesToDecide) {
            return false;
        }

        const selectedItemsLength = selectedProfiles.length;

        if (profilesToDecide.length > 2) {
            return selectedItemsLength < 2;
        }

        return 0 === selectedItemsLength;
    }, [profilesToDecide, selectedItems, selectedProfiles]);

    const toggleSelection = useCallback((item) => {
        const newValue = !selectedItems[item];
        setSelectedItems({ ...selectedItems, [item]: newValue });
    }, [selectedItems, setSelectedItems]);

    useEffect(() => {
        if (profilesToDecide && 0 === profilesToDecide.length) {
            push('/step/6/transition');
        }
        setSelectedItems({});
    }, [profilesToDecide]);

    if (!profilesToDecide) {
        return null;
    }

    return (
        <Container>
            <Panel><StepList step={exerciseNumber} /></Panel>
            <MainWithStep
                className="step6-rank"
                subheader={t('exercise.subheader', { stepNumber: exerciseNumber })}
                title={!canGuessMajorMinor
                    ? t('step6.rank.title.choose_two_activities')
                    : t('step6.rank.title.choose_one_activity')}
                footer={(
                    <Button
                        className="button--block-mobile button--black"
                        disabled={canSelectMore}
                        text={t('common.validate')}
                        onClick={() => {
                            if (!canGuessMajorMinor) {
                                dispatch(updateProfilesToDecide(selectedProfiles, lastFormId));
                            } else {
                                const major = Object.keys(selectedItems).find((profile) => selectedItems[profile]);
                                const minor = profilesToDecide.find((profile) => profile !== major);
                                dispatch(chooseArchetype(major, minor, lastFormId));
                            }
                        }}
                    />
                )}
            >
                <div className="step6-rank__content">
                    {
                        profilesToDecide.map((profile) => (
                            <ActivityDisplayer
                                key={profile}
                                profile={profile}
                                question={t(questionsToDecide[profile])}
                                onInteraction={toggleSelection}
                                disabled={!canSelectMore && !selectedItems[profile]}
                                isActive={!!selectedItems[profile]}
                            />
                        ))
                    }
                </div>
            </MainWithStep>
        </Container>
    );
};

Step6Rank.propTypes = {
    lastFormId: PropTypes.string.isRequired,
};

export default requireLastFormId(Step6Rank);
