import { CLEAN_USER, SET_USER, SET_USER_LANGUAGE } from '../actions/user';

const initialState = null;

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case SET_USER:
            return payload.user;
        case SET_USER_LANGUAGE:
            return {
                ...state,
                language: payload.language,
            };
        case CLEAN_USER:
            return null;
        default:
            return state;
    }
};

export default reducer;
