import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/common/LanguageSwitcher';
import ErrorPage from '../../components/layout/ErrorPage';
import { DEFAULT_MISSING_PAGE_LANGUAGE } from '../../utils/lang';

const MissingLanguage = () => {
    const { t } = useTranslation();

    return (
        <ErrorPage
            content={t('missing_language.content', { lng: DEFAULT_MISSING_PAGE_LANGUAGE })}
            title={t('missing_language.title', { lng: DEFAULT_MISSING_PAGE_LANGUAGE })}
        >
            <LanguageSwitcher label={t('missing_language.language', { lng: DEFAULT_MISSING_PAGE_LANGUAGE })} />
        </ErrorPage>
    );
};

export default MissingLanguage;
