import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './style.scss';

const Colored = ({ children, color, fillHeight = false, withPadding = false }) => (
    <div className={classNames({ 'with-padding': withPadding }, { 'fill-height': fillHeight }, `colored--${color}`)}>
        {children}
    </div>
);

Colored.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
    fillHeight: PropTypes.bool,
    withPadding: PropTypes.bool,
};

export default Colored;
