import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import requireAuth from './hoc/requireAuth';
import LanguageContext from './Language/LanguageContext';
import LocalizedRoutes from './Language/LocalizedRoutes';
import RedirectToLocalizedRoute from './Language/RedirectToLocalizedRoute';
import MissingLanguage from './routes/MissingLanguage';

const Routes = () => {
    const { allowedLanguages } = useContext(LanguageContext);

    return (
        <Switch>
            <Route exact path="/account" component={requireAuth(<></>)} />
            <Route
                component={requireAuth(MissingLanguage)}
                path="/missing-language"
                exact
            />
            <Route path={`/:lang(${allowedLanguages.join('|')})`}>
                <LocalizedRoutes />
            </Route>
            <Route path="*">
                <RedirectToLocalizedRoute />
            </Route>
        </Switch>
    );
};

export default Routes;
