import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useUserLanguage from '../../../hooks/useUserLanguage';
import './style.scss';

const Step = ({ description, number, state, onRestart }) => {
    const { t } = useTranslation();
    const lang = useUserLanguage();
    let imgPath = '/img/icons/locked-step.svg';

    if ('done' === state) {
        imgPath = '/img/icons/check-step.svg';
    } else if ('current' === state) {
        imgPath = '/img/icons/unlocked-step.svg';
    }

    return (
        <li className={`step-item ${state}`}>
            <img src={imgPath} alt="logo" />
            <div className="step-item__content">
                <div className="step-item__description">
                    <h3>{`${t('common.exercise')} ${number}`}</h3>
                    <span>{t(description)}</span>
                </div>
                {'done' === state && (
                    <button type="button" role="link" onClick={() => onRestart(number)}>
                        <span>{t('common.start_again_exercise')}</span>
                    </button>
                )}
                {'current' === state && (
                    <Link to={`/${lang}/step/${number}`}>{t('common.start_exercise')}</Link>
                )}
            </div>
        </li>
    );
};

Step.propTypes = {
    description: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    onRestart: PropTypes.func.isRequired,
    state: PropTypes.string.isRequired,
};

export default Step;
