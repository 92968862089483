import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { STATUS_ADMIN } from '../../constants';
import LanguageContext from '../Language/LanguageContext';

const CheckLogin = ({ id, status }) => {
    const { language } = useContext(LanguageContext);
    if (id) {
        if (STATUS_ADMIN === status) {
            return <Redirect to={`/${language}/admin`} />;
        }

        return <Redirect to={`/${language}/account`} />;
    }

    return <Redirect to={`/${language}`} />;
};

CheckLogin.propTypes = {
    id: PropTypes.string,
    status: PropTypes.string,
};

const mapStateToProps = ({ auth: { id, status } }) => ({ id, status });

export default connect(mapStateToProps)(CheckLogin);
