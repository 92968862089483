import React, { useContext, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import requireAuth from '../hoc/requireAuth';
import requireUser from '../hoc/requireUser';
import { useAppData } from '../hooks/useAppData';
import Account from '../routes/Account';
import Admin from '../routes/Admin';
import CheckLogin from '../routes/CheckLogin';
import Home from '../routes/Home';
import Confidentiality from '../routes/Legal/Confidentiality';
import LegalNotices from '../routes/Legal/LegalNotices';
import TermOfServices from '../routes/Legal/TermOfServices';
import NotFound from '../routes/NotFound';
import PasswordReset from '../routes/PasswordReset';
import Exercise from '../routes/personality-form/Exercise';
import ExerciseRedirector from '../routes/personality-form/ExerciseRedirector';
import RestitutionWrapper from '../routes/personality-form/RestitutionWrapper';
import LastResults from '../routes/personality-form/LastResults';
import Results from '../routes/personality-form/Results';
import Step6Rank from '../routes/personality-form/Step6-rank';
import Step7 from '../routes/personality-form/Form';
import StepTransition from '../routes/personality-form/StepTransition';
import StepTransitionGif from '../routes/personality-form/StepTransitionGif';
import Logout from '../routes/Logout';
import { getAllLangs } from '../utils/lang';
import LanguageContext from './LanguageContext';

const LocalizedRoutes = () => {
    const { language } = useContext(LanguageContext);
    const { MARCO_V4_URL, isWebview } = useAppData();
    const { pathname } = useLocation();

    const redirectToMarcoV4 = (path = '') => {
        // Redirect to last results, otherwise, redirect to account
        if (path.includes('last-results')) {
            window.location.href = `${MARCO_V4_URL}/${language}/last-results${isWebview ? '?webview=true' : ''}`;
        } else {
            window.location.href = `${MARCO_V4_URL}/${language}/account${isWebview ? '?webview=true' : ''}`;
        }

        return null;
    };

    const Routes = useMemo(() => (
        <Switch>
            <Route exact path={`/${language}`} component={Home} />
            <Route exact path={`/${language}/logout`} component={Logout} />
            <Route exact path={`/${language}/check-login`} component={requireAuth(CheckLogin)} />
            <Route exact path={`/${language}/confidentiality`} component={Confidentiality} />
            <Route exact path={`/${language}/legalnotices`} component={LegalNotices} />
            <Route exact path={`/${language}/password-reset/:token`} component={PasswordReset} />
            <Route exact path={`/${language}/tos`} component={TermOfServices} />
            <Route exact path="/fr/admin" component={requireAuth(Admin)} />
            <Route exact path={`/${language}/admin`} component={() => <Redirect to="/fr/admin" />} />
            <Route exact path={`/${language}/pdf/restitution/:formId`} component={RestitutionWrapper} />
            <Route
                path={`/:lang(${getAllLangs().filter(({ isAvailableV4 }) => isAvailableV4).map(({ keyLang }) => keyLang).join('|')})/*`}
                component={() => redirectToMarcoV4(pathname.slice(3, pathname.length))}
            />
            <Route exact path={`/${language}/last-results`} component={requireAuth(requireUser(LastResults))} />
            <Route exact path={`/${language}/results/:formId`} component={Results} />
            <Route exact path={`/${language}/account`} component={requireAuth(requireUser(Account))} />
            <Route exact path={`/${language}/step/:step([1-6]{1})`} component={requireAuth(requireUser(ExerciseRedirector))} />
            <Route exact path={`/${language}/step/:step([0-6]{1})/transition`} component={requireAuth(requireUser(StepTransition))} />
            <Route exact path={`/${language}/step/:step([1-6]{1})/:substep(1?[0-9]{1,2}|2[0-6][0-9]|27[0-6])`} component={requireAuth(requireUser(Exercise))} />
            <Route exact path={`/${language}/step/6/rank`} component={requireAuth(requireUser(Step6Rank))} />
            <Route exact path={`/${language}/step/7`} component={requireAuth(requireUser(Step7))} />
            <Route exact path={`/${language}/processing/:formId`} component={requireAuth(requireUser(StepTransitionGif))} />
            <Route exact path={`/${language}/*`} component={NotFound} status={404} />
        </Switch>
    ), [language]);

    return Routes;
};

export default LocalizedRoutes;
