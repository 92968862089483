import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { shareOnSocialNetwork } from '../../utils/shareOnSocialNetwork';
import Button from '../common/Button';
import './index.scss';

const ShareButton = ({ afterOnClick, buttonContent, shareUrl, socialNetwork }) => {
    const { t } = useTranslation();

    return (
        <Button
            className="button--black"
            onClick={() => {
                ReactGA.send({
                    hitType: 'social',
                    socialNetwork,
                    socialAction: 'Share',
                    socialTarget: shareUrl,
                });
                shareOnSocialNetwork(socialNetwork, shareUrl);
                afterOnClick();
            }}
            text={t(buttonContent)}
        />
    );
};

ShareButton.propTypes = {
    afterOnClick: PropTypes.func.isRequired,
    buttonContent: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    socialNetwork: PropTypes.string.isRequired,
};

export default ShareButton;
