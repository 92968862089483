import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';

const HomeContentCard = ({ image, text, title }) => (
    <div className="home-card">
        <img alt="icon" className="home-card__img" src={image} />
        <h3 className="home-card__title">{title}</h3>
        <p className="home-card__description">{text}</p>
    </div>
);

HomeContentCard.propTypes = {
    image: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default HomeContentCard;
