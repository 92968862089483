import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS_ADMIN } from '../../../../constants';
import { getUser } from '../../../actions';
import { useAppData } from '../../../hooks/useAppData';
import useLocalizedHistoryPush from '../../../hooks/useLocalizedHistoryPush';
import { useMenu } from '../../../hooks/useMenu';
import useWindowSize from '../../../hooks/useWindowSize';
import { checkToken } from '../../../jt-client/actions';
import Backdrop from '../../common/Backdrop';
import CloseButton from '../CloseButton';
import Menu from '../Menu';
import MenuButton from '../MenuButton';
import SecondaryMenu from '../SecondaryMenu';

import './style.scss';

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const Panel = ({ children }) => {
    const { menuIsOpen } = useMenu();
    const dispatch = useDispatch();
    const { API_URL } = useAppData();
    const isMobile = 'xs' === useWindowSize().size;
    const { auth: { status }, user } = useSelector((state) => state);
    const push = useLocalizedHistoryPush();

    useEffect(() => {
        async function fetchToken() {
            const result = await dispatch(checkToken());
            if (result && result.success) {
                await dispatch(getUser(API_URL));
            }
        }
        if (!user) {
            fetchToken();
        }
    }, []);

    return (
        <div className="container__panel">
            <ConditionalWrapper
                condition={menuIsOpen}
                wrapper={(child) => <Backdrop className="panel__backdrop" zIndex={499}>{child}</Backdrop>}
            >
                <div className={`panel ${menuIsOpen ? 'panel--open' : ''}`}>
                    <header className="panel__header">
                        <button
                            className="marco-btn"
                            disabled={status === STATUS_ADMIN}
                            onClick={() => push('/account')}
                            type="button"
                        >
                            <img
                                alt="Menu"
                                src="/img/logos/marco_beta.svg"
                                width="107"
                            />
                        </button>
                        {menuIsOpen ? <CloseButton /> : <MenuButton />}
                    </header>
                    {(!isMobile && !menuIsOpen)
                        && (
                            <div className={classnames('panel__inner', { 'without-border': status === STATUS_ADMIN })}>
                                {children}
                            </div>
                        )}
                    {menuIsOpen && <Menu />}
                    {(!isMobile || menuIsOpen) && <SecondaryMenu />}
                </div>
            </ConditionalWrapper>
        </div>
    );
};
Panel.propTypes = {
    children: PropTypes.node,
};
export default Panel;
