import PropTypes from 'prop-types';
import archetypeTypes from './archetypeTypes';

const psicoProfileTypes = PropTypes.shape({
    archetype_female: archetypeTypes,
    archetype_male: archetypeTypes,
    major: PropTypes.string.isRequired,
    minor: PropTypes.string.isRequired,
    spider_text_female: PropTypes.string.isRequired,
    spider_text_male: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
}).isRequired;

export default psicoProfileTypes;
