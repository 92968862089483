import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Colored from '../Colored';
import './style.scss';

const PageProfiles = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="page-profiles__header">
                <Colored color="grey" withPadding>
                    <h2 className="pdf__title">{t('pdf.pageProfiles.title')}</h2>
                    <p><Trans i18nKey="pdf.pageProfiles.subtitle" /></p>
                </Colored>
            </div>
            <div className="page-profiles__content">
                <p className="strong">{t('pdf.pageProfiles.text')}</p>
                <div className="two-cols">
                    <div className="col left-col">
                        <div className="page-profiles__profile-description">
                            <p className="strong">{t('common.profile.P')}</p>
                            {t('common.profile.P.description')}
                        </div>
                        <div className="page-profiles__profile-description">
                            <p className="strong">{t('common.profile.C')}</p>
                            {t('common.profile.C.description')}
                        </div>
                        <div className="page-profiles__profile-description">
                            <p className="strong">{t('common.profile.I')}</p>
                            {`${t('common.profile.I.description1')} ${t('common.profile.I.description2')}`}
                        </div>
                    </div>
                    <div className="col right-col">
                        <div className="page-profiles__profile-description">
                            <p className="strong">{t('common.profile.S')}</p>
                            {t('common.profile.S.description')}
                        </div>
                        <div className="page-profiles__profile-description">
                            <p className="strong">{t('common.profile.O')}</p>
                            {t('common.profile.O.description')}
                        </div>
                        <div className="page-profiles__conclusion">
                            <Trans i18nKey="pdf.pageProfiles.conclusion" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageProfiles;
