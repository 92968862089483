const initialState = {
    lastForm: undefined,
    lastCompletedForm: undefined,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case 'SET_LAST_FORM':
            return { ...state, ...payload };
        case 'RESET_LAST_FORM':
            return initialState;
        case 'SET_LAST_COMPLETED_FORM':
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default reducer;
