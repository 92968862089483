export const setLastForm = (lastForm) => ({
    type: 'SET_LAST_FORM',
    lastForm,
});

export const deleteLastForm = () => ({
    type: 'RESET_LAST_FORM',
});

export const setLastCompletedForm = (lastCompletedForm) => ({
    type: 'SET_LAST_COMPLETED_FORM',
    lastCompletedForm,
});
