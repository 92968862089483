import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../common/Modal';

const DeleteAccountModal = ({
    onClose,
    onConfirm,
}) => {
    const { t } = useTranslation();

    return (
        <Modal closeModal={onClose} title={t('delete_account.title')} fullscreen>
            <p>{t('delete_account.message')}</p>
            <p>{t('delete_account.message.previousTools')}</p>
            <button
                className="button button--block button--black base-margin"
                onClick={() => { onClose(); onConfirm(); }}
                type="button"
            >
                {t('delete_account.yes')}
            </button>
            <button
                className="button button--block button--grey base-margin"
                onClick={onClose}
                type="button"
            >
                {t('delete_account.cancel')}
            </button>
        </Modal>
    );
};

DeleteAccountModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default DeleteAccountModal;
