import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import Container from '../../components/layout/Container';
import MainWithBackButton from '../../components/layout/MainWithBackButton';
import Panel from '../../components/layout/Panel';
import LegalPanelContent from '../../components/PanelDescription/LegalPanelContent';

const Confidentiality = () => {
    const { t } = useTranslation();

    useEffect(() => {
        ReactGA.event({
            category: 'Static Page',
            label: 'Confidentiality',
            action: 'pageview',
        });
    }, []);

    return (
        <Container bgColor="white">
            <Panel><LegalPanelContent /></Panel>
            <MainWithBackButton title={t('common.confidentiality.title')}>
                <div
                    className="static-page"
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: t('common.confidentiality.content') }}
                />
            </MainWithBackButton>
        </Container>
    );
};

export default Confidentiality;
