import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkToken, currentUser, getUser } from '../actions';
import { DEFAULT_LANGUAGE } from '../utils/lang';
import withBrowserLanguage from './withBrowserLanguage';

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = {
    checkToken,
    getCurrentUser: currentUser,
    getUserAction: getUser,
};

const requireAuth = (ComposedComponent) => {
    class Auth extends Component {
        constructor(props) {
            super(props);

            this.state = {
                loading: true,
            };
        }

        async componentDidMount() {
            const { browserLanguage, checkToken: verify, getCurrentUser, getUserAction, history, user } = this.props;

            try {
                const result = await verify();
                if (!result || !result.success) {
                    history.push(`/${browserLanguage}`);
                }

                await getCurrentUser();
                if (!user) {
                    await getUserAction();
                }
                this.setState({ loading: false });
            } catch (e) {
                history.push(`/${DEFAULT_LANGUAGE}`);
            }
        }

        render() {
            const { loading } = this.state;

            if (loading) {
                return null;
            }

            return (
                <Fragment>
                    <ComposedComponent {...this.props} />
                </Fragment>
            );
        }
    }

    Auth.propTypes = {
        browserLanguage: PropTypes.string.isRequired,
        checkToken: PropTypes.func.isRequired,
        getCurrentUser: PropTypes.func.isRequired,
        getUserAction: PropTypes.func.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        user: PropTypes.shape({
            id: PropTypes.string,
        }),
    };

    return withRouter(withBrowserLanguage(connect(mapStateToProps, mapDispatchToProps)(Auth)));
};

export default requireAuth;
