import Texts from '../../constants/texts';

const exerciseKeyword = 'step';

export const getExerciseInfos = (exerciseNumber) => {
    if (0 === exerciseNumber) {
        return {
            stepName: undefined,
            transition: {},
        };
    }

    return Texts[`${exerciseKeyword}${exerciseNumber}`];
};

// eslint-disable-next-line max-len
export const getExerciseFirstQuestionNumber = (exerciseNumber) => getExerciseInfos(exerciseNumber).questions[0].key;

const getExerciseLastQuestionNumber = (exerciseNumber) => {
    const { questions } = getExerciseInfos(exerciseNumber);

    return questions[questions.length - 1].key;
};

// eslint-disable-next-line max-len
export const isExerciseFirstQuestion = (exerciseNumber, questionNumber) => getExerciseFirstQuestionNumber(exerciseNumber) === questionNumber;

// eslint-disable-next-line max-len
export const isExerciseLastQuestion = (exerciseNumber, questionNumber) => getExerciseLastQuestionNumber(exerciseNumber) === questionNumber;

// eslint-disable-next-line max-len
export const isFinalQuestion = (exerciseNumber, questionNumber) => Object.keys(Texts).length === exerciseNumber && isExerciseLastQuestion(exerciseNumber, questionNumber);

export const getQuestion = (exerciseNumber, questionNumber) => {
    const { questions } = getExerciseInfos(exerciseNumber);

    return questions.find(({ key }) => key === questionNumber);
};
