import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { passwordsMatch, required } from '../../utils/validators';
import Button from '../common/Button';
import SimpleField from '../Form/Field';
import './style.scss';

const PasswordResetForm = ({ error, handleSubmit, pristine, submitting }) => {
    const { t } = useTranslation();

    return (
        <form className="base-margin" onSubmit={handleSubmit} method="post">
            {error && <div className="error">{error}</div>}
            <Field
                placeholder={t('password_reset.placeholder.new_password')}
                name="password"
                component={SimpleField}
                type="password"
                validate={[required]}
                autocapitalize="none"
            />
            <Field
                placeholder={t('password_reset.placeholder.confirm_new_password')}
                name="password_confirm"
                component={SimpleField}
                type="password"
                validate={[required, passwordsMatch]}
                autocapitalize="none"
            />
            <Button
                className="button button--block button--blue base-margin"
                disabled={pristine || submitting || submitting}
                onClick={handleSubmit}
                submitting={submitting}
                text={t('auth.lost_password.title')}
            />
        </form>
    );
};

PasswordResetForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export default withRouter(reduxForm({
    form: 'PasswordResetForm',
})(PasswordResetForm));
