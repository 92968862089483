export const LINKEDIN = 'Linkedin';
export const FACEBOOK = 'Facebook';

const socialNetworkConfig = {
    [LINKEDIN]: {
        networkUrl: 'https://www.linkedin.com/shareArticle?mini=true&url=',
    },
    [FACEBOOK]: {
        networkUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
    },
};

export const shareOnSocialNetwork = (network, link) => {
    const { networkUrl } = socialNetworkConfig[network];
    const url = `${networkUrl}${encodeURIComponent(`${link}?redirectHome=true`)}`;
    window.open(
        url,
        'share',
        '_blank, width=700, height=700, resizable=yes, scrollbars=yes, left=50px, top=50px',
    );

    return false;
};
