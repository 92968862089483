import { reset, SubmissionError } from 'redux-form';
import Errors from '../../constants/errors.json';
import { GET, PATCH, POST } from '../utils/http';
import { setLastForm } from './reconnect';

export const resetForm = () => async (dispatch) => {
    await GET('/api/personality-form/start');

    return dispatch(reset('personalityForm'));
};

export const createForm = () => async () => {
    const formId = await POST('/api/personality-form');
    setLastForm(formId);
};

export const getLastForms = () => async (dispatch) => {
    const { form, lastCompletedForm } = await GET('/api/personality-form/last_form');
    dispatch({
        type: 'SET_LAST_FORM',
        lastForm: form,
    });

    dispatch({
        type: 'SET_LAST_COMPLETED_FORM',
        lastCompletedForm,
    });
};

export const deleteResponseFromExercise = (lastFormId, exerciseNumber) => async (dispatch) => {
    try {
        const form = await PATCH('/api/personality-form/delete-responses', {
            exerciseNumber,
            lastFormId,
        });
        setLastForm(form);
        dispatch({ type: 'SET_VALUES', values: form.responses });
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

export const saveResponse = (lastFormId, exerciseNumber, questionNumber, value) => async () => {
    try {
        await PATCH('/api/personality-form/exercises', {
            exerciseNumber,
            questionNumber,
            value,
            lastFormId,
        });
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

export const saveFavoriteActivities = (values, lastFormId) => async () => {
    try {
        await PATCH('/api/personality-form/step2-bis', {
            values,
            lastFormId,
        });
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

export const saveSocioDemoResponse = (values, lastFormId) => async () => {
    try {
        PATCH('/api/personality-form/step7', {
            ...values,
            lastFormId,
        });
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};

export const saveRoti = (values, lastFormId) => async () => {
    try {
        PATCH('/api/personality-form/roti', {
            ...values,
            lastFormId,
        });
    } catch (e) {
        throw new SubmissionError({ _error: Errors.serverError });
    }
};
