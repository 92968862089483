import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { STATUS_ADMIN } from '../../constants';

const mapStateToProps = ({ auth: { status } }) => ({ status });

const requireUser = (ComposedComponent) => {
    const User = (props) => {
        const { history, status } = props;
        useEffect(() => {
            if (STATUS_ADMIN === status) {
                history.replace('/admin');
            }
        }, [status]);

        return (
            <ComposedComponent {...props} />
        );
    };

    User.propTypes = {
        history: PropTypes.shape({
            replace: PropTypes.func.isRequired,
        }).isRequired,
        status: PropTypes.string,
    };

    return withRouter(connect(mapStateToProps)(User));
};

export default requireUser;
