import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { useUrlParams } from '../../../hooks/useLocalizedHistoryPush';
import { FORMS } from '../../../utils/constants';
import { emailFormat, required } from '../../../utils/validators';
import Button from '../../common/Button';
import SimpleField from '../../Form/Field';

const PasswordLostForm = ({ error, handleSubmit, submitting, valid }) => {
    const { t } = useTranslation();
    const updateUrlParams = useUrlParams();

    return (
        <>
            <h1 className="title">{t('auth.lost_password.title')}</h1>
            <p className="subtitle">{ t('auth.lost_password.subtitle') }</p>
            <form className="base-margin" method="post" onSubmit={handleSubmit}>
                {error && <div className="error">{error}</div>}
                <Field
                    component={SimpleField}
                    label={t('common.email_address')}
                    name="email"
                    type="email"
                    validate={[required, emailFormat]}
                />
                <Button
                    className="button--block button--black base-margin"
                    disabled={!valid}
                    submitting={submitting}
                    text={t('auth.lost_password.title')}
                    submit
                />
                <div className="base-margin">
                    <button
                        className="link text--medium"
                        onClick={() => updateUrlParams({ open: FORMS.LOGIN })}
                        type="button"
                    >
                        {t('auth.lost_password.go_to_login')}
                    </button>
                </div>
            </form>
        </>
    );
};

PasswordLostForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool,
};

export default reduxForm({
    form: 'PasswordLostForm',
})(PasswordLostForm);
