import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';

const MainWithCard = ({ children, header, title, withSmallCard = false }) => (
    <>
        <div className="main-card__header">{header}</div>
        <h1 className="title main-card__title">{title}</h1>
        <div className={`main-card ${withSmallCard ? 'main-card--small' : ''}`}>
            {children}
        </div>
    </>
);

MainWithCard.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.node,
    title: PropTypes.string,
    withSmallCard: PropTypes.bool,
};

export default MainWithCard;
