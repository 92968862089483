import PropTypes from 'prop-types';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import useLocalizedHistoryPush from '../../../hooks/useLocalizedHistoryPush';
import PauseConfirmation from '../../PauseConfirmation';
import './style.scss';

const Navigation = ({ current, disableBack, displayBar, onBack, total }) => {
    const { t } = useTranslation();
    const push = useLocalizedHistoryPush();
    const renderCurrentQuestion = (num) => (num < 10 ? `0${num}` : num);

    const percentage = `${total ? (current / total) * 100 : 0}%`;

    const onPause = () => {
        confirmAlert({
            /* eslint-disable-next-line */
            customUI: ({ onClose }) => (
                <PauseConfirmation onClose={onClose} push={push} />
            ),
        });
    };

    return (
        <div className="nav">
            <button
                className={`nav__button ${disableBack ? 'nav__button--disabled' : ''}`}
                disabled={disableBack}
                onClick={onBack}
                type="button"
            >
                <img alt={t('navigation.back.button')} src="/img/icons/back.svg" />
            </button>
            {displayBar ? (
                <div className="bar">
                    <div
                        className="bar__content"
                        style={
                            {
                                width: percentage,
                            }
                        }
                    />
                </div>
            ) : (
                <div className="question">
                    <span className="question__current">{renderCurrentQuestion(current)}</span>
                    {total && (
                        <span className="question__total">
                            /
                            {total}
                        </span>
                    )}
                </div>
            )}
            <button
                className="nav__button"
                onClick={onPause}
                type="button"
            >
                <img alt={t('navigation.pause.button')} src="/img/icons/break.svg" />
            </button>
        </div>
    );
};

Navigation.propTypes = {
    current: PropTypes.number.isRequired,
    disableBack: PropTypes.bool,
    displayBar: PropTypes.bool,
    onBack: PropTypes.func,
    total: PropTypes.number,
};

export default Navigation;
