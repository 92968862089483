import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';

const Main = ({ className = '', children, centered }) => (
    <main className={classnames('main', className, { 'main--centered': centered })}>{children}</main>
);

Main.propTypes = {
    centered: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default Main;
