export const getUrlWithoutLanguage = (language, url) => {
    // eslint-disable-next-line prefer-template
    const re = new RegExp(`^/${language}/|^/${language}$|^/`);

    return url.replace(re, '');
};

export const getUrlLanguage = (url) => {
    const prefix = url.match(/^(\/[a-z]{2}\/)|^\/[a-z]{2}$/g);
    if (prefix) {
        return prefix[0].substring(1, 3);
    }

    return undefined;
};
