import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { saveSocioDemoResponse } from '../../../actions';
import Container from '../../../components/layout/Container';
import Main from '../../../components/layout/Main';
import MainWithCard from '../../../components/layout/MainWithCard';
import Panel from '../../../components/layout/Panel';
import DescriptionForm from '../../../components/PanelDescription/DescriptionForm';
import ProfilingForm from '../../../components/ProfilingForm';
import requireLastFormId from '../../../hoc/requireLastFormId';
import useLocalizedHistoryPush from '../../../hooks/useLocalizedHistoryPush';

const Form = ({ lastFormId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();

    const submitForm = async (values) => {
        dispatch(saveSocioDemoResponse(values, lastFormId));
    };

    const goToResults = () => {
        ReactGA.event({
            category: 'link',
            action: 'go-to-results',
        });
        push(`/processing/${lastFormId}`);
    };

    if (!lastFormId) {
        return null;
    }

    return (
        <Container bgColor="purple">
            <Panel><DescriptionForm /></Panel>
            <Main>
                <MainWithCard title={t('sociodemo.title')}>
                    <ProfilingForm
                        onSubmit={(values) => submitForm(values)}
                        onSubmitSuccess={goToResults}
                    />
                </MainWithCard>
            </Main>
        </Container>
    );
};

Form.propTypes = {
    lastFormId: PropTypes.string,
};

export default compose(requireLastFormId)(Form);
