import React from 'react';
import { useTranslation } from 'react-i18next';
import PasswordResetForm from '../../components/PasswordResetForm';
import useLocalizedHistoryPush from '../../hooks/useLocalizedHistoryPush';
import { updatePassword } from '../../jt-client/actions/user';
import './style.scss';

const onSubmit = ({ password }, dispatch, props) => {
    const { match: { params: { token } } } = props;

    return dispatch(updatePassword(password, token));
};

const PasswordReset = () => {
    const push = useLocalizedHistoryPush();
    const { t } = useTranslation();

    return (
        <div className="reset-password">
            <h1 className="title">
                {t('password_reset.title')}
            </h1>
            <PasswordResetForm
                onSubmit={onSubmit}
                onSubmitSuccess={() => push('/account')}
            />
        </div>
    );
};

export default PasswordReset;
