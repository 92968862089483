import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';

const Container = ({ bgColor = 'white', children, scroll = 'yes' }) => (
    <div className={`container container--${bgColor} container--scroll-${scroll}`}>
        {children}
    </div>
);

Container.propTypes = {
    bgColor: PropTypes.string,
    children: PropTypes.node.isRequired,
    scroll: PropTypes.string,
};

export default Container;
