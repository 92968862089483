import { C, I, O, P, S } from './psico_profiles';

const questionsToDecide = {
    [P]: 'step6.rank.question.P',
    [S]: 'step6.rank.question.S',
    [I]: 'step6.rank.question.I',
    [C]: 'step6.rank.question.C',
    [O]: 'step6.rank.question.O',
};

export default questionsToDecide;
