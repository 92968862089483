import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastForms, } from '../../../actions/personalityForm';
import useLocalizedHistoryPush from '../../../hooks/useLocalizedHistoryPush';
import Container from '../../../components/layout/Container';
import Button from '../../../components/common/Button';
import Panel from '../../../components/layout/Panel';
import { useTranslation } from 'react-i18next';
import './style.scss';

const LastResults = () => {
    const { reconnect: { lastCompletedForm } } = useSelector((state) => state);
    const dispatch = useDispatch();
    const push = useLocalizedHistoryPush();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getLastForms());
    }, []);

    // if a value is found, redirects to the correct result
    if (lastCompletedForm) {
        push(`/results/${lastCompletedForm.id}`);
        return null;
    }

    // otherwise, lastCompletedForm is undefined because the getLastForms request isn't complete
    if (lastCompletedForm !== null) {
        return <p>loading...</p>;

    }

    // if lastCompletedForm is null, there is no last completed forms so redirects the user to its account
    return (
        <Container>
            <Panel />
            <div className='last-results'>
                <img alt="" src="/img/results/top.svg" />
                <div className='last-results__container'>
                    <div className='last-results__no-results'>
                        <p>{t('results.empty.text')}</p>
                        <Button
                            className="button--block last-results__back-to-home"
                            onClick={() => push('/account')}
                            text={t('results.empty.button')}
                        />
                    </div>
                </div>
            </div>
        </Container>
    );

}

export default LastResults;
