import PropTypes from 'prop-types';
import { splitAt, splitEvery } from 'ramda';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as branches from '../../../../constants/branches';
import Branches from '../Branches';
import Colored from '../Colored';
import PageLayout from '../PageLayout';
import './style.scss';

const elementForFirstPage = 8;
const elementByPage = 9;

const splitOnPage = (t) => {
    const branchesByPage = [];
    const splitedBranches = splitAt(
        elementForFirstPage,
        Object.values(branches).sort((a, b) => t(`common.branch.${a}`) > t(`common.branch.${b}`)),
    );

    // For first page
    branchesByPage.push(splitedBranches[0]);

    // For other page
    splitEvery(elementByPage, splitedBranches[1]).forEach((pageBranches) => {
        branchesByPage.push(pageBranches);
    });

    return branchesByPage;
};

const PageProfessionalGroups = ({ date, firstPageIndex, user }) => {
    const { t } = useTranslation();
    const branchesByPage = splitOnPage(t);
    const firstPageBranches = branchesByPage.shift();

    let index = firstPageIndex;
    const getNextPageIndex = () => index++;

    return (
        <div className="page-professional-groups">
            <PageLayout page={getNextPageIndex()} date={date} user={user} withoutPadding>
                <div className="page-professional-groups__header">
                    <Colored color="grey" withPadding>
                        <h2 className="pdf__title">{t('pdf.pageProfessionalGroups.title')}</h2>
                        <p><Trans i18nKey="pdf.pageProfessionalGroups.subtitle" /></p>
                    </Colored>
                </div>
                <div className="page-professional-groups__content">
                    <p className="strong">{t('pdf.pageProfessionalGroups.text')}</p>
                    <Branches branches={firstPageBranches} />
                </div>
            </PageLayout>
            { branchesByPage.map((pageBranches) => (
                <PageLayout page={getNextPageIndex()} date={date} user={user}>
                    <Branches branches={pageBranches} />
                </PageLayout>
            ))}
        </div>
    );
};

PageProfessionalGroups.propTypes = {
    date: PropTypes.string,
    firstPageIndex: PropTypes.number.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
    }),
};

export default PageProfessionalGroups;
